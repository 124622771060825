@import "../../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.leaderboard_container {
  height: 100%;
  border-radius: 10px;
  width: 80%;

  @include mobile {
    width: 100%;
  }

  .leaderboard_container_top {
    font-family: "Lexend", sans-serif;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px 10px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-family: "Squada One", sans-serif;
    font-size: 22px;
    font-weight: 400;
    font-style: normal;

    .leaderboard_container_top_top {
      padding: 20px;

      .leaderboard_container_top_genre {
        h1 {
          font-size: 20px;
        }
      }

      .leaderboard_container_top_message {
        span {
          font-size: 14px;
          color: #a8a8a8;
          font-weight: 500;
        }
      }
    }

    .leaderboard_container_top_bottom {
      width: 100%;
      height: 30%;
      padding: 20px;
      background-color: rgb(40 16 58);
      display: grid;
      grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr;

      h1 {
        @include mobile {
          font-size: 12px;
        }
      }

      > h1:nth-child(1) {
        margin-right: 10px;
        text-align: center;
      }

      > h1:nth-child(2) {
        margin-left: 10px;
      }

      > h1:nth-child(3) {
        text-align: center;
      }

      > h1:nth-child(4) {
        text-align: center;
        margin-left: 10px;
      }

      // show under the title
      .prize {
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          content: "in FANS Tokens";
          font-size: 12px;
          color: #a8a8a8;
          font-weight: 500;
        }
      }
    }
  }

  .leaderboard_container_bottom {
    ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.5px solid $border-color;
      padding: 10px;
      display: grid;
      grid-template-columns: 0.5fr 1fr 0.5fr 0.5fr;

      .star_rank {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .star_rank span {
        z-index: 1;
        /* Set a higher z-index for the span */
        color: #282828;
      }

      span {
        font-size: 20px;
        font-weight: bold;
      }

      > span:nth-child(1) {
        text-align: center;
      }

      .prize {
        text-align: center;
      }

      .user_leaderboard {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50px;
          background: #5e0acc;
          flex-shrink: 0;

          @include mobile {
            width: 30px;
            height: 30px;
          }
        }

        &:hover {
          text-decoration-line: underline;
        }
      }

      @include mobile {
        span {
          font-size: 14px;
        }
      }

      > span:nth-child(3) {
        text-align: center;
      }
    }
  }
}
