@import "../../../styles/mixins";
.mainFeed {
  width: 100%;
  height: max-content;
  overflow: hidden;
  padding-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;

  @include mobile {
    width: 100%;
    height: max-content;
    padding: 0;
    padding-top: 20px;
    padding-bottom: 70px;
  }
  &__line {
    border-bottom: 2px solid lightgray;
    width: 70%;
    margin-left: 20%;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.profile {
  width: 100%;
  height: min-content;
  //overflow: scroll;

  margin: 24px 24px 0 24px;
  @include mobile {
    margin: 0;
  }

  .header {
    width: 98%;
    min-height: 225px;
    margin: auto;
    background: $secondarybg;
    border-radius: $border-radius;
    position: relative;

    @include mobile {
      max-width: 100%;
      min-height: 125px;
      border-radius: 0;
    }
  }
  .profile_header {
    display: flex;
    flex-direction: row;
    z-index: 2;
    position: relative;
    margin-top: -75px;
    padding: 10px 20px;
    //margin: -75px 40px 0 40px ;

    .avatar {
      width: 140px;
      height: 140px;
      background: white;
      //border: solid 1px #21203E;
      border-radius: 100px;
      z-index: 2;

      @include mobile {
        width: 110px;
        height: 110px;
      }

      img {
        width: 100%;
        height: 100%;
        border-radius: 100px;
        padding: 5px;
        @include mobile {
          width: auto;
        }
      }
    }
    .name {
      display: flex;
      align-self: flex-end;
      font-family: "Squada One", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
    }

    .content {
      position: relative;

      display: flex;
      flex: 1;
      justify-content: space-between;
      text-align: left;
      z-index: 2;

      @include mobile {
        bottom: 30px;
      }

      .creator {
        width: 100%;
        .details {
          display: flex;
          justify-content: space-between;
          margin: 30px 0px 10px 0px;

          .actions {
            /*  position: absolute;
             top: 0;
             right: 0;
             z-index: 3; */
            button {
              max-width: 135px;
              //width: 97px !important;
              // height: 40px;
              border: 1px solid rgba(105, 89, 230, 0.4) !important;
              border-radius: 50px !important;
              padding: 8px 24px !important;

              @include mobile {
                padding: 8px 10px !important;
              }
            }
          }
        }
        .name {
          display: flex;
          align-items: center;
          gap: 10px;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;

          color: #f6f5ff;
        }
      }
    }
  }
}

.postItem {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-self: center;
  margin-bottom: 40px;

  &__header {
    display: flex;
    gap: 10px;
    img {
      width: 46px;
      height: 46px;
      border-radius: 50px;
    }
    p {
      font-weight: bold;
    }
    span {
      font-size: 14px;
      color: gray;
    }
  }
  &__img {
    width: 300px;

    img {
      width: 100%;
      height: 300px;
      object-fit: cover;
      border-radius: 10px;
      border: 1px solid $border-color;
      border-radius: 10px;
    }
    @include mobile {
      width: 260px;
      img {
        height: 260px;
      }
    }
  }
  &__reactions {
    display: flex;
    gap: 20px;
  }
}
