.user_item_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  cursor: pointer;
  margin-bottom: 30px;
}

.user_item_avatar {
  flex-shrink: 0;
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  img {
    width: 100% !important;
    height: 100% !important;
    border-radius: 50%;
  }
}

.user_item_info {
  display: flex;
  flex-direction: column;
  gap: 3px;

  .user_displayname {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;
    font-size: 17px;
  }
  .user_username {
    color: #666;
  }
  .user_description p {
    text-align: justify;
  }
}
