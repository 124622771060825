@import "../../../styles/mixins.scss";

.block {
  display: flex;

  @include mobile {
    display: block;
  }

  .daynamicSlider {
    display: flex;
    border: none;
    margin: 0;
    width: 100%;

    @include mobile {
      width: 100%;
      height: 100%;
    }

    .activeImage {
      width: 100%;
      max-width: 400px;
      height: 100%;
      //max-height: 500px;
      overflow: hidden;
      margin: 0 auto;
      border-radius: 10px;

      @include mobile {
        max-width: 400px;
        height: 500px;
      }
      .image {
        width: 100%;
        height: 100%;
        min-height: 320px;
        overflow: hidden;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }

    div {
      //width: 33.33%;
      border: none;
      margin: 0;
      //padding: 0 10px;
    }

    img {
      width: 100%;
      //flex-shrink: 0;
      //min-width: 100%;
      min-height: 100%;
    }
  }

  .slider {
    @include mobile {
      display: flex;
    }
  }

  .slider_img {
    /* height: 215px; */
    width: max-content;
    //height: 160px;
    height: 90px;
    margin: 10px;
    background-color: rgb(0, 0, 0) 03B;

    border: 2px solid #22203b;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
      border: 3px solid #8877ea;
      cursor: pointer;
    }
  }
}
