@import "../../../../styles/mixins.scss";
.edit_profile_cover {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0px;

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(30 31 36 / 82%);
    z-index: 1;

    backdrop-filter: blur(10px);
  }
}

.profile_cover {
  position: relative;
  margin-bottom: 10px;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: var(--bg-secondary);
  justify-content: center;
  display: flex;
}

.profile_cover .cover {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}
.cover_crooper {
  height: 100%;
}

.cover_crooper img {
  object-fit: cover;
  width: 100%;
}
.cover_crooper .reactEasyCrop_Container {
  border-radius: 10px;
  object-fit: cover;
  width: 100%;
}

.save_changes_cover {
  z-index: 9;
  position: relative;
  left: 75%;
  display: flex;
  padding: 10px 20px;
  font-weight: 500;
  font-size: 14px;
  color: var(--color-primary);
  gap: 15px;
}

.save_changes_left {
  display: flex;
  flex-direction: row;
}
.open_cover_update {
  background: white;
  width: 28px;
  height: 28px;
  display: flex;
  gap: 20px !important;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  color: #111;
  border-radius: 20px;
  cursor: pointer;

  .blue_btn {
    position: absolute;
    display: flex;
    top: 10px;
    padding: 10px !important;
    background: $primary;
    color: #fff;
    border-radius: 20px;
    //box-shadow: 0 0 5px 6px #7569a2;
    animation: jump 1s infinite;
    &:hover {
      animation: none;
    }
  }

  .clear_btn {
    position: absolute;
    display: flex;
    top: 10px;
    padding: 10px !important;
    background: #fff;
    color: #111;
    border-radius: 20px;
    //box-shadow: 0 0 5px 6px #a7a5a9;
  }
}
.open_cover_update:hover {
  background: rgba(183, 183, 183, 0.9);
}

@media (max-width: 460px) {
  .save_changes_cover {
    left: 70%;
  }
}

// css scale animation
@keyframes jump {
  0% {
    transform: scale(1);
    background: $primary;
  }
  50% {
    transform: scale(1.5);
    background: #7569a2;
  }
  100% {
    transform: scale(1);
    background: $primary;
  }
}