@import "../../../../styles/mixins.scss";
$--max-width: 1440px;

.voteModalBlur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(27, 28, 28, 0.768);
  backdrop-filter: blur(5px);
  z-index: 10000;
}

.voteModal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0c0d0f;
  box-shadow: 0 12px 20px 0 var(--shadow-1), 0 2px 4px 0 var(--shadow-2),
    inset 0 0 0 1px var(--shadow-inset);
  width: 500px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 90%;
  display: flex;
  flex-direction: column;
  z-index: 10001;
  .header {
    display: flex;
    justify-content: flex-end;
    padding: 10px 20px;
    border-bottom: 1px solid $border-color;
    .header_close {
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
  }



  .launchpad {
    width: 100%;
    margin: 0 auto;
    .launchViewHeader {
      display: flex;
      flex-direction: column;
      gap: 20px;
      max-width: $--max-width;
      padding-top: 20px !important;
      padding-bottom: 70px !important;
      padding-left: 20px;
      padding-right: 20px;
      margin: 0 auto;
      margin-top: 40px;
      position: relative;
      z-index: 0;

      @media (max-width: 1300px) {
        margin-top: 20px;

        .launchViewLeft {
          width: 50%;
        }

        .launchViewRight {
          width: 50%;
          margin-left: 0;
          margin-top: 20px;
        }
      }

      @include mobile {
        margin-top: 20px;
        display: block;

        .launchViewLeft {
          width: 100%;
        }

        .launchViewRight {
          width: 100%;
          margin-left: 0;
          margin-top: 20px;
        }
      }

      .titleBar {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        font-size: 16px;
        color: #8d8d8d;
        //padding: 0 15px;
        margin-bottom: 33px;

        @include mobile {
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-end;
        }

        .title {
          display: flex;
          align-items: center;

          img {
            @include mobile {
              display: none;
            }
          }

          h6 {
            font-family: "shapiro65_light_heavy_wide";
            font-size: 32px;
            font-style: normal;
            font-weight: 400;
            line-height: 49px;
            letter-spacing: 0em;
            text-align: left;
            color: white;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;

            @include mobile {
              font-size: 18px;
              padding: 0 0 !important;
            }
          }
        }

        .status {
          position: absolute;
          top: 15px;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 14px;
          color: #fff;
          width: fit-content;
          margin: 0 auto;
          padding: 4px 8px;
          border: 1px solid transparent;
          border-radius: 10px;
          background: #fa7753;
          border: 0.5px solid #ffffff;
          border-radius: 4px;
          z-index: 9999;

          @include mobile {
            // margin-top: 100px !important;
          }
        }

        .activeTab {
          color: #fff;
          background: linear-gradient(#3168f7, #01cdfd) padding-box,
            linear-gradient(#3168f7, #01cdfd) border-box;
        }
      }
    

    .launchViewLeft {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;

      @include mobile {
        display: block;
        width: 100%;
        margin-top: 0;
      }

      .block {
        display: flex;

        @include mobile {
          display: block;
        }

        p {
          margin-top: 36px;
          font-weight: 300;
          font-size: 18px;
          text-align: left;
          line-height: 30px;
          color: #e1e1e1;
          padding: 0;
        }

        @include mobile {
          width: 100%;
        }
      }

      h6 {
        padding: 0 15px;
        font-size: 21px;
        text-align: center;
        margin-bottom: 0;
        font-family: Lexend;
        font-weight: 700;
        position: initial;
      }

      p {
        padding: 0 15px;
        font-size: 14px;
        text-align: center;
        color: #8d8d8d;
      }

      .social {
        text-align: center;
        align-items: end;

        a {
          padding: 0 10px;
          position: relative;
          top: -12px;
        }
      }
    }




    .launchViewRight {
      width: 100%;
      margin-left: 0px !important;

      .project_social {
        //margin-top: 20px;
        color: white;
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        align-items: baseline;
        justify-content: right;

        img {
          width: 40px;
        }

        .fav_btn {
          min-width: 0 !important;
          background-color: transparent;
          border-color: transparent;

          &:hover {
            background-color: transparent !important;
            border-color: transparent !important;
          }
        }
      }

      .nft_view {
        @include mobile {
          width: 100%;
          border-radius: 10px;
        }

        .mint_normal {
          width: 100%;
          height: 77px;
          border-radius: 12px;
          background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
            #f6f5ff;

          span {
            font-weight: 600;
            font-size: 18px;
            line-height: 24px;

            color: #fff;
          }
        }

        .price_text {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: rgba(246, 245, 255, 0.6);
        }

        .mediaPlayer {
          width: 100%;
          background: $secondarybg;
          border-radius: 50px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          padding: 26px;
          border: solid 1px $border-color;

          .playSong {
            width: 100%;
            text-align: right;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            color: rgba(246, 245, 255, 0.6);
          }

          .playing {
            width: 100%;
            text-align: right;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            color: rgba(246, 245, 255, 0.6);
          }

          .equalizer {
            width: 75%;
            padding: 10px 0 !important;
            left: 0;

            @include mobile {
              padding: 25px 0 !important;
            }
          }
        }

        .mint_live {
          width: 100%;
          background: $secondarybg;
          border-radius: 12px;

          display: flex;
          justify-content: space-between;
          align-items: center;

          padding: 26px;
          margin: 20px 0;
          border: solid 1px $border-color;

          @include mobile {
            display: block;
            padding: 20px;
          }

          .endDate {
            text-align: center;
            width: 100%;
          }

          .countdown {
            text-align: center;
            width: 100%;

            font-weight: 700;
            font-size: 24px;
            line-height: 29px;

            h6 {
              font-weight: 700;
              font-size: 24px;
              line-height: 29px;

              color: #f6f5ff;
            }

            div {
              margin-bottom: 0px !important;
            }
          }

          .price_text {
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            color: rgba(246, 245, 255, 0.6);
          }

          .price_value {
            background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
              #f6f5ff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            font-weight: 400;
            font-size: 24px;
            line-height: 29px;

            padding: 10px 0;
          }

          .convert {
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            letter-spacing: 0.02em;
            color: #fafafa;

            span {
              color: rgba(250, 250, 250, 0.7);
            }
          }

          .buy {
            //background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
            //width: 50%;
            background-color: $blue;
            border-radius: 12px;

            font-weight: 600;
            font-size: 18px;
            line-height: 24px;
            color: #fff;
            padding: 20px;

            @include mobile {
              width: 100%;
              margin-top: 20px;
            }

            &:disabled {
              opacity: 0.5;
            }
          }
        }

        .mint_countdown {
          span {
            font-weight: 700;
            font-size: 18px;
            line-height: 29px;
          }

          @include mobile {
            width: 100%;
            text-align: center;
            margin: auto;
            margin-top: 20px !important;
            padding: 20px 0;
            border-top: solid 1px $border-color;
            span {
              font-size: 22px;
            }
          }

          .soon {
            background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
              #f6f5ff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
          }
        }

        .mint {
          width: 100%;
          height: 77px;
          background: #171936;
          border-radius: 12px;

          span {
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;

            h6 {
              font-weight: 700;
              font-size: 24px;
              line-height: 29px;

              margin-top: 20px;

              color: #f6f5ff;
            }
          }

          .soon {
            background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
              #f6f5ff;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            background-clip: text;
            text-fill-color: transparent;

            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
          }
        }

        .status_message {
          display: block;
          margin: 25px 0;
          text-align: center;
          font-size: 12px;
          line-height: 19px;
          color: #8d8d8d;
        }

        .ProgressBar {
          .container {
            background: #3b3a3a;
            border-radius: 10px;
          }
        }

        .header_timer {
          //margin-bottom: 40px;

          .time {
            margin: auto;
            background: #2a2938;
            border: 1px solid #262636;
            border-radius: 15px;
            padding: 20px;
            margin-top: 30px;
            height: 150px;
            width: 100%;

            h6 {
              font-family: "Lexend";
              font-style: normal;
              font-weight: 900;
              font-size: 34px;
              line-height: 80px;
              text-align: left;

              letter-spacing: 0.07em;
              text-transform: uppercase;

              color: #ffffff;

              span {
                opacity: 0.4;
                padding: 0 15px;
              }
            }

            p {
              font-family: "Lexend";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              /* identical to box height, or 158% */

              text-align: left;
              letter-spacing: 0.13em;
              text-transform: uppercase;

              color: #8d8d8d;
            }
          }
        }

        .header {
          position: relative;

          h3 {
            font-weight: 600;
            font-size: 32px;
            line-height: 40px;
            color: #fff;
          }

          .about {
            font-weight: 600;
            font-size: 16px;
            line-height: 34px;
            color: #c0bdbd;

            padding: 10px 20px;
          }

          .benefits {
            display: block;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
            letter-spacing: 0.1em;
            text-transform: uppercase;

            color: #fffafd;

            .benefit_details {
              margin: 20px 0;

              label p {
                display: flex;
                gap: 10px;
                font-size: 14px;
                line-height: 26px;
                color: #f6f5ff;
                padding: 7px 0;
                font-weight: 500 !important;

                .text-green {
                  color: #32fe9a;
                }

                b {
                  font-weight: 300 !important;
                  color: #fff;

                  span {
                    color: #8d8d8d;
                  }
                }
              }
            }
          }

          .artist {
            display: flex;
            gap: 10%;
            border-top: 1px solid $border-color;
            border-bottom: 1px solid $border-color;
            border-left-width: 0;
            border-right-width: 0;
            border-image: linear-gradient(
                to right,
                transparent,
                $border-color,
                transparent
              )
              1 stretch;

            margin: 20px 0;
            padding: 24px 0;

            .divider {
              background: rgba(56, 54, 78, 0.6);
              height: auto;
              width: 2px;
              margin: 2px;
            }

            span {
              font-weight: 400;
              font-size: 11px;
              line-height: 13px;
              letter-spacing: 0.1em;
              text-transform: uppercase;

              color: #fffafd;
            }

            p {
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              letter-spacing: 0.02em;

              color: #8877ea;
            }

            .profile {
              display: flex;
              gap: 10px;
              align-items: flex-end;

              img {
                width: 40px !important;
                height: 40px;
                border-radius: 50px;
              }
            }
          }

          .price {
            display: grid;
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 37px;
            letter-spacing: -0.02em;
            color: #ffffff;

            span {
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 21px;
              color: #8d8d8d;
            }
          }

          .status {
            font-size: 14px;
            color: #fff;
            background: #fd3eda;
            border: 1.5px solid #ffffff;
            border-radius: 4px;
            padding: 4px 16px;
            position: absolute;
            right: 0%;
            top: 5%;
          }

          .upocming {
            font-size: 14px;
            text-align: center;
            color: #fff;
            background: #fa7753;
            border: 1.5px solid #ffffff;
            border-radius: 4px;
            padding: 4px 16px;
            width: 120px;
            margin: auto;
            margin-bottom: 15px;
          }

          .time {
            margin: auto;
            background: #2a2938;
            border: 1px solid #262636;
            border-radius: 15px;
            padding: 20px;
            //margin-top: 30px;
            height: 150px;
            width: 100%;

            h6 {
              font-family: "Lexend";
              font-style: normal;
              font-weight: 900;
              font-size: 34px;
              line-height: 80px;
              text-align: center;

              letter-spacing: 0.07em;
              text-transform: uppercase;

              color: #ffffff;

              span {
                opacity: 0.4;
                padding: 0 15px;
              }
            }

            .time_detail {
              font-family: "Lexend";
              font-style: normal;
              font-weight: 400;
              font-size: 12px !important;
              line-height: 0;
              text-align: center;

              letter-spacing: 0.07em;
              text-transform: uppercase;

              color: #8d8d8d;

              span {
                opacity: 0.4;
                padding: 0 15px;
              }
            }

            p {
              font-family: "Lexend";
              font-style: normal;
              font-weight: 500;
              font-size: 16px;
              line-height: 19px;
              /* identical to box height, or 158% */

              text-align: center;
              letter-spacing: 0.13em;
              text-transform: uppercase;

              color: #8d8d8d;
            }

            @include mobile {
              h6 {
                font-size: 24px !important;

                span {
                  opacity: 0.4;
                  padding: 0 10px;
                }
              }

              .time_detail {
                font-size: 10px !important;

                span {
                  opacity: 0.4;
                  padding: 0 10px;
                }
              }
            }
          }
        }

        .details {
          margin: 20px 0;

          label p {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            color: #8d8d8d;
            padding: 7px 0;
            font-weight: 300 !important;

            .text-green {
              color: #32fe9a;
            }

            b {
              font-weight: 300 !important;
              color: #fff;

              span {
                color: #8d8d8d;
              }
            }
          }
        }

        .mint {
          margin-top: 40px;

          span {
            font-weight: 300px;
            color: #8d8d8d;
            font-size: 12px;
            line-height: 19px;

            b {
              color: #fff;
              margin: 0 5px;
            }
          }
        }

        .input {
          display: inline-flex;
          width: 100%;
          margin: 20px 0;

          input {
            width: 100%;
            border: solid 2px #2f2f43;
            padding: 15px;
            text-align: center;
            font-weight: 700;
            font-size: 21px;
            line-height: 26px;
          }

          button {
            width: 90px;
            color: #fff;
            transition: ease-in-out 0.2s;
            border: 1px solid #2f2f43 !important;
            background: var(--secondary-bg)
              radial-gradient(circle, transparent 1%, var(--secondary-bg) 1%)
              center/15000% !important;

            &:first-child {
              border-right: none !important;
              border-radius: 10px 0 0 10px;
            }

            &:last-child {
              border-left: none !important;
              border-radius: 0 10px 10px 0;
            }

            &:last-child,
            &:first-child {
              transition: background 0.4s;

              &:hover {
                background: #01cffdb0
                  radial-gradient(circle, transparent 1%, #01cffdb0 1%)
                  center/15000% !important;
              }

              &:disabled {
                background: var(--disabled-bg)
                  radial-gradient(circle, transparent 1%, var(--disabled-bg) 1%)
                  center/15000% !important;

                &:active {
                  background-color: #272738 !important;
                  background-size: 100% !important;
                  transition: background 0s;
                }
              }

              &:active {
                background-color: #01cdfd !important;
                background-size: 100% !important;
                transition: background 0s;
              }
            }
          }
        }

        .footer {
          padding: 0;
          margin: 0 !important;
          float: none;

          #mint {
            &:disabled {
              img {
                filter: brightness(6);
              }
            }
          }

          button {
            background: #01cdfd
              radial-gradient(circle, transparent 1%, #01cdfd 1%) center/15000%;
            border-radius: 10px;
            width: 100%;
            height: 52px;
            margin-top: 20px;
            font-size: 18px;
            color: #1b1b1b;
            font-weight: 500;
            transition: background 0.8s;

            &:hover {
              background: #01cffdca
                radial-gradient(circle, transparent 1%, #01cffdca 1%)
                center/15000%;
              border: solid 2px #01cdfd;
            }

            &:active {
              background-color: #13c3ea83;
              background-size: 100%;
              transition: background 0s;
            }

            &:disabled {
              background: var(--disabled-bg);
              border: solid 2px var(--disabled-bg);
              color: var(--disabled-color);
            }
          }
        }
      }
    }
  }
}

  @include mobile {
    width: 90%;
  }
}
