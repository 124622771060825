.starting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;
}

.middle_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #1c1c1c;
  width: 551px;
  border-radius: 20px;
}

.middle_img {
  background-image: url("https://loop-markets.directus.app/assets/eea7f3e5-9581-4d0e-8447-7813b5bc3b5f");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  h1 {
    font-size: 28px;
  }
  border-radius: 20px 20px 0px 0px;
}

.text {
  padding: 20px;
  margin-bottom: 40px;
}

.get_started_button {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 28px;
  border: 1px solid #5500ab;
  color: white;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    transition: 0.5s;
    background: #5500ab;
  }
}
.progress_button_loading {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 24px;
  border: 2px solid #8168e1;
  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }
  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
}
@media (max-width: 768px) {
  .middle_content {
    width: 100%;
  }
  .middle_img {
    height: 100px;
  }
  .get_started_button {
    padding: 5px 20px;
  }
  .progress_button_loading {
    padding: 10px 20px;
  }
}
