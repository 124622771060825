@import "../../../../styles/mixins";

.about_me_container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  color: white;
  margin-top: 12px;
  @include mobile {
    padding: 12px;
  }
}

.about_me {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.about_me span {
  display: flex;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
}
.about_me p {
  text-align: justify;
}
.music {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.music span {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 18px;
  font-weight: 600;
}
.music_buttons {
  display: flex;
  flex-wrap: wrap;

  @include mobile {
    margin: 0 auto;
    width: 100%;
  }
}
.music_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid #332f62;
  color: #887dea;
  font-weight: 500;
  border-radius: 20px;
  padding: 4px 10px;
  margin: 5px;
  cursor: pointer;
  width: fit-content;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }
}

.nfts {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.nft_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    cursor: pointer;
    color: #8168e1;
    &:hover {
      text-decoration: underline;
    }
  }
}
.nfts span {
  font-size: 18px;
  font-weight: 600;
}

.nft_list {
  width: 580px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: hidden;

  @include mobile {
    margin: 0 auto;
    width: 100%;
  }
}
.nft {
  flex: 1; /* Each .nft will take an equal part of the .nft_list */
  max-width: calc(
    (100% - 2 * 16px) / 3
  ); /* 2 * 16px for the gap between items */
  height: 350px;
  border: 1px solid $border-color;
  border-radius: 10px;
  overflow-y: hidden;
  cursor: pointer;
  img {
    object-fit: cover;
    width: 100%;
    height: 80%;
    border-radius: 10px;
  }
  @include mobile {
    height: 300px;
  }
}
.nft_info {
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  span {
    font-size: 16px;
  }
  p {
    font-size: 14px;
    color: #9c9b9b;
    text-align: justify;
  }
}

.nft:hover {
  opacity: 0.8;
}

.fav_songs {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.fav_songs_title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  p {
    cursor: pointer;
    color: #8168e1;
    &:hover {
      text-decoration: underline;
    }
  }
}
.fav_songs span {
  font-size: 18px;
  font-weight: 600;
}

.fav_list {
  width: 580px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  overflow-x: hidden;

  @include mobile {
    margin: 0 auto;
    width: 100%;
  }
}
.fav {
  flex: 1; /* Each image will take an equal part of the .favsongs_list */
  max-width: calc(
    (100% - 2 * 16px) / 3
  ); /* 2 * 16px for the gap between items */
  height: 350px;
  border: 1px solid $border-color;
  border-radius: 10px;
  overflow-y: hidden;
  cursor: pointer;
  img {
    object-fit: cover;
    width: 100%;
    height: 70%;
    border-radius: 10px;
  }
  p {
    padding: 0 10px;
  }
  @include mobile {
    height: 300px;
  }
}

.fav:hover {
  opacity: 0.8;
}
.text_area {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

.text_area_modal {
  width: 95%;
  height: auto;
  background: $input-color;
  padding: 20px;
  border-radius: 20px;
  text-align: justify;
  overflow-y: hidden;
  border: 1px solid $border-color;

  &:focus {
    border: 1.5px solid $border-color;
    box-shadow: 0px 0px 10px $border-color;
  }
}

.text_area_options {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.characters_counter {
  font-size: 14px;
  color: grey;
}
@media (max-width: 555px) {
}

@media (max-width: 500px) {
  .nft {
    height: 250px;
  }
}
@media (max-width: 400px) {
  .nft {
    height: 200px;
  }
}
@media (max-width: 500px) {
  .fav {
    height: 250px;
  }
}
@media (max-width: 400px) {
  .fav {
    height: 200px;
  }
}
