.purple_button {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 24px;
  border: 2px solid #8168e1;

  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }

  &:disabled {
    border: 2px solid #84828c !important;
    color: #fff !important;

    &:hover {
      background: transparent !important;
      color: #fff !important;
      cursor: not-allowed;
    }
  }

  span {
    color: white;
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
  }
}