@import "../../../styles/mixins.scss";

.messages_cont {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  .message_header {
    width: 100%;
    border-bottom: 1px solid $border-color;
    height: 50px !important;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    .header_infos {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 10px;
      padding-left: 20px;
      .conversation_image {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #2c2c2c;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
        }
      }
    }
  }
}
@media (min-width: 600px) {
}
