@import "../../../styles/mixins";

.media_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #282828;
  padding: 5px 20px;
  color: #fff;
  position: fixed;
  bottom: -100%; // Start hidden
  width: 100%;
  z-index: 9999;
  transition: bottom 0.5s ease; // Smooth transition
  &.show {
    bottom: 0; // Slide up when show class is added
  }

  @include mobile {
    bottom: -100%; // Start hidden on mobile too
    padding: 5px 10px !important;
    &.show {
      bottom: 60px; // Adjust for mobile if needed
    }
  }
  &__song_info {
    display: flex;
    align-items: center;
    height: 100%;
    svg {
      cursor: pointer;
      &:hover {
        color: rgb(188, 185, 185);
      }
    }
    img {
      width: 50px;
      height: 50px;
      margin-right: 5px;
      margin-left: 5px;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
    h4 {
      font-size: 1rem;
      margin: 0;
    }
    p {
      font-size: 0.875rem;
      color: #b3b3b3;
      margin: 0;
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }
    @include mobile {
      img {
        width: 40px;
        height: 40px;
      }
      h4 {
        font-size: 14px;
        margin: 0;
      }
      p {
        font-size: 12px;
        color: #b3b3b3;
        margin: 0;
      }
    }
  }
  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0px;
    height: 100%;

    &_control_buttons {
      display: flex;
      align-items: center;
      @include mobile {
        svg {
          width: 25px;
        }
      }
    }
  }
}

.icon_button {
  color: white;
}

.play_pause_button {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play_pause_button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.icon {
  color: white;
}

.time_info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 250px;
  color: white;
  gap: 0.5rem;
  span {
    font-size: 12px;
  }
  @include mobile {
    width: 100%;
    position: absolute;
    bottom: -14px;
    left: 12px;
    width: 90%;
    .current_time {
      display: none;
    }
    .total_time {
      display: none;
    }
  }
}

.volume_control {
  display: flex;
  align-items: center;
  width: 150px;
  @include mobile {
    display: none;
  }
}

.volume_slider {
  color: white;
}
