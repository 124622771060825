@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.inbox_container {
  width: 100%;
  height: 100%;
  //padding: 0 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 0 !important;
  padding-top: 10px;
  @include mobile {
    padding: 10px !important;
    margin-top: 30px;
  }

  @include tablet {
    padding: 20px;
  }
  .inbox_title_subtitle_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .inbox_title {
      display: flex;
      flex-direction: column;
      gap: 20px;
      h3 {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 0;
        text-align: left;
        letter-spacing: -0.02em;
        color: #fff0fa;

        @include mobile {
          padding: 0;
          font-size: 25px;
          line-height: 24px;
        }
      }
      .subHeading {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        letter-spacing: -0.02em;
        color: #fff;
        opacity: 0.7;

        @include mobile {
          padding: 10px 0;
        }
      }
    }
    .leaderboard_input {
      background-color: #282828;
      height: 40px;
      border-radius: 5px;
      width: 250px;
      display: flex;
      align-items: center;
      margin-right: 33px;
      input {
        padding: 10px;
        width: 100%;
      }
    }
  }
  .chat_container {
    //border: 1px solid $border-color;
    width: 100%;
    //height: 500px;
    height: calc(100vh - 120px);
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;

    @include mobile {
      flex-direction: column;
      height: calc(100vh - 180px);
    }
  }
  .conversations_container {
    height: 100%;
    width: 35%;
    overflow-y: auto;

    .conversation_header {
      position: sticky;
      //height: 10%;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
    }
    .conversations_list {
      width: 100%;
      height: auto;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .no_convo {
        color: #515050;
      }
    }
  }
  .messages_container {
    border-left: 1px solid $border-color;
    width: 65%;
    height: 100%;
    .no_convo_selected {
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        width: 240px;
        height: auto;
      }
    }
  }
}

@media (max-width: 600px) {
  .conversations_container {
    width: 100% !important;
  }
  .messages_container {
    width: 100% !important;
  }
  .chat_container {
  }
}
