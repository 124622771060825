@import '../../../styles/mixins';
.preview_container {
  width: 70%;
  background-color: $border-color;
  border: solid 1px $border-color;
  height: 65vh;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-content: center;
  gap: 20px;

  .preview_title {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    h1 {
      color: white;
    }
    span {
      font-size: 14px;
      color: white;
    }
  }
  .preview {
    width: 50%;
    height: 70%;
    background-color: $border-color;
    border: solid 1px $border-color;
    position: relative;
    margin: 0 auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .preview_profile_img {
      position: relative;
      bottom: 12%;
      left: 10%;
      height: 110px;
      width: 110px;
      background-color: #363636;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .preview_cover {
    width: 100%;
    height: 40%;
    background-color: #565656;
    position: relative;
    margin: 0 auto;
    border-radius: 5px 5px 0px 0px;
  }

  .preview_infos {
    position: relative;
    bottom: 20px;
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    span {
      color: rgb(35, 82, 157);
    }
    p {
      font-size: 14px;
      color: #818181;
    }
  }
}
@media (max-width: 768px) {
  .preview_container {
    width: 100%;
    background: transparent;
    height: 65vh;
    border-radius: 5px;
    border: 0px solid #2c2c2c !important;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 20px;
  }
  .preview {
    width: 100% !important;
    height: 60% !important;
    background-color: #181818;
    position: relative;
    margin: 0 auto;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    .preview_profile_img {
      position: relative;
      bottom: 12%;
      left: 10%;
      height: 110px;
      width: 110px;
      background-color: #363636;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
}
