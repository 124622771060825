.modal_mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 40px;
  .modal_top {
    background-image: url("https://loop-markets.directus.app/assets/1d5b3695-c005-4a72-9847-439fc4ce341d");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;
    margin: 0 auto;
    text-align: center;

    h1 {
      font-size: 38px;
      font-weight: 700;
      color: white;
    }
  }
  .modal_bottom {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 200px;

    span {
      font-size: 28px;
      width: 70%;
      word-break: break-word;
    }
  }
}
