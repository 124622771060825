@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");
.theme {
  width: 100%;
  height: 100%;
  //max-height: 800px;
  //height: 970px;
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  h1 {
    font-size: 24px;
    font-family: "Squada One", sans-serif;
    font-weight: 400;
    font-style: normal;
  }
  .input {
    display: flex;
    flex-direction: column;
    gap: 10px;
    input {
      border: 1px solid $border-color;
      width: fit-content;
      border-radius: 20px;
    }
  }
}
