@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.discover {
  width: 100%;
  height: 100%;
  //max-height: 800px;
  //height: 970px;
  padding: 0 20px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 60px;

  .banner {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }

    .image {
      width: 100%;
      height: auto;
      border-radius: 10px;
      background-color: $background;
      img {
        width: 100%;
        height: auto;
        object-fit: fill;
        border-radius: 10px;
      }
    }
    .title {
      h1 {
        font-size: 24px;
        font-family: "Squada One", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
    }
  }

  @include mobile {
    padding: 10px !important;
    margin-top: 0px;
  }

  @include tablet {
    padding: 20px;
  }

  .collection_slider {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
    .title {
      h1 {
        font-size: 24px;
        font-family: "Squada One", sans-serif;
        font-weight: 400;
        font-style: normal;
      }
    }
  }

  .artist_slider {
    display: flex;
    flex-direction: column;
    width: 100%;

    gap: 20px;
    h1 {
      font-size: 24px;
      font-family: "Squada One", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
  }
}
