@import "../../../styles/mixins.scss";

.messages_container_1 {
  background-repeat: no-repeat;
  position: relative;
  height: 100%;
  //background-color: $border-color;
  overflow-y: auto;
}
.message_option {
  color: white;
  cursor: pointer;
}
.message_message {
  float: left;
  height: 100%;
  font-size: 14px;
  padding-right: 2rem;
  color: #fff;
}
.message_text {
  word-break: break-word;
}
.messages {
  /* Set the height and overflow properties for scrollbar functionality */
  height: 100%; /* Adjust height as needed */
  overflow-y: scroll;
  overflow-x: hidden; /* Hide horizontal scrollbar if present */
  position: relative;

  padding: 10px;
}
.message-container {
  width: 100%;
  display: flex;
  margin-top: 2px;
  gap: 0.75rem;
  max-width: 20rem; /* Adjust max-width as needed */
}
.container {
  width: 100%;
  display: flex;
  gap: 10px;
  margin-top: 0.5rem; /* Adjust as needed */
  margin-left: 0.75rem; /* Adjust spacing as needed */
  max-width: 20rem; /* Adjust as needed */
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .message_container_message {
    position: relative;
    display: flex;
    height: 100%;
    padding: 0.5rem;
    border-radius: 8px;
    .timestamp {
      position: relative;
      font-size: 0.75rem;
      color: $white;
    }
  }
}
.container_me {
  margin-left: auto;
  justify-content: flex-end;
}

.bg_green {
  background-color: $secondary;
}
.bg_dark {
  background-color: $primary;
}
@media (max-width: 600px) {
  .container {
    max-width: 15rem;
  }
}
