.step_six {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;
  width: 100vw;
  .step_six_top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    span {
      text-align: center !important;
    }
  }

  border-top: 1px solid #1d1d1d;
  h1 {
    font-size: 26px;
    font-weight: bold;
    margin-top: 30px;
  }
  .step_six_nfts_support {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    height: 100%;
    .nft_card_complete {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      .nft_price {
        background-color: #323232;
        border-radius: 50px;
        padding: 5px 10px;
        width: 100px;
        text-align: center;
        span {
          font-weight: bold;
          color: #fff;
        }
      }
    }
    .nftcard {
      display: flex;
      align-items: center;
      position: relative;
      width: 235px;
      height: 310px;
      border: 1px solid #202020;
    }
  }
}

.progress_bar {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  .progresss {
    border-radius: 7px;
    width: 40%;
    height: 5px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
  }
  .progresss::-webkit-progress-bar {
    background-color: rgb(35, 35, 35);
    border-radius: 7px;
  }
  .progresss::-webkit-progress-value {
    background-color: rgb(59, 252, 168);
    border-radius: 7px;
  }
  .progresss::-moz-progress-bar {
    /* style rules */
  }
}
@media (max-width: 770px) {
  .step_six_top {
    gap: 20px;
    h1 {
      text-align: center;
    }
    span {
      text-align: center;
      margin-left: 40px;
      margin-right: 40px;
    }
  }
  .step_six_nfts_support {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
}
.step_five_right_content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70%;
  background: #5c5c5c;

  .step_five_preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    .step_five_loop_logo {
      margin-top: -20px;
      width: 80px;
      height: 80px;
      img {
        width: 100%;
      }
    }
    .avatar_container {
      -webkit-border-radius: 50px; /* Add this line */
      border-radius: 50px; /* Add this line */
      .step_five_avatar {
        width: 100px;
        height: 100px;
        -webkit-border-radius: 50px; /* Add this line */
        border-radius: 50px; /* Add this line */
        background: #1a1a1a;
        img {
          height: 100%;
          width: 100%;
          -webkit-border-radius: 50px; /* Add this line */
          border-radius: 50px; /* Add this line */
        }
      }
    }

    .step_five_display_name {
      margin-top: 10px;
      margin-bottom: 20px;
      background: rgb(0, 8, 17);
      padding: 2px 5px;
      border-radius: 10px;
      text-align: center;

      span {
        color: white;
        font-size: 16px;
        font-weight: bold;
        width: 130px;
        text-align: center;
      }
    }
    .preview_bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      margin-top: 30px;

      .preview_bottom_left {
        width: 80px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .preview_bottom_rigth {
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 770px) {
  .step_five_right_content {
    width: 60%;
    height: 100% !important;
  }
}

.step_five_right_content_support {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #5c5c5c;

  span {
    font-size: 18px;
    font-family: "Bebas Neue", sans-serif;
    color: white;
  }
  .step_five_preview_support {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
    gap: 10px;
    width: 180px;
    .avatar_container_support {
      -webkit-border-radius: 50px; /* Add this line */
      border-radius: 50px; /* Add this line */
      .step_five_avatar_support {
        width: 40px;
        height: 40px;
        -webkit-border-radius: 50px; /* Add this line */
        border-radius: 50px; /* Add this line */
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
          -webkit-border-radius: 50px; /* Add this line */
          border-radius: 50px; /* Add this line */
        }
      }
    }

    .step_five_display_name_support {
      span {
        color: white;
        font-size: 14px;
        font-weight: bold;
        font-family: sans-serif;
        word-break: break-word;
      }
    }
  }
}
