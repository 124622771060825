@import "../../styles/mixins.scss";

.modal_login {
  width: 550px !important;

  @include mobile {
    width: 90% !important;
  }
}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 99999999 !important; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: scroll; /* Enable scroll if needed */
  background-color: rgba(221, 222, 222, 0.59); /* Fallback color */
  //background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  // -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(10px);
  /*
  div {
    background: #1e1e2a !important;
  }
  */
}

/* Modal Content */
.modalContent {
  position: relative;
  //background-color: $mainbg;
  //background-color: #1e1e2a;
  //border: $border-color;
  //border-color: $border-color;
  margin: auto;
  padding: 0;
  width: 40%;
  max-width: 777px;
  //min-height: 357px;
  min-height: 150px;
  //box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  border-radius: 10px;
  z-index: 9999;
  @include mobile {
    width: 90%;
    margin: auto !important;
  }
}

/* Add Animation */
@-webkit-keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

@keyframes animatetop {
  from {
    top: -300px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

/* The Close Button */
.modalImageHeader {
  background-size: cover;
  padding: 70px 0px;
  //padding: 10px 16px;
  //background-color: $darkblue;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: space-between; */

  @include mobile {
    padding: 20px 10px;
  }

  // put overlay on image
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
    border-radius: 10px;
  }

  h1 {
    font-weight: 500;
    margin: auto;
    position: relative;
    z-index: 2;
  }
  p {
    font-size: 14px;
    margin: 0;
    color: $white;
    position: relative;
    z-index: 2;
  }
  .close {
    color: white;
    cursor: pointer;
    font-size: 32px;
    font-weight: 100;
    position: absolute;
    top: 0;
    right: 20px;
    z-index: 2;
  }
}

.modalHeader {
  padding: 10px 0px;
  //padding: 10px 16px;
  //background-color: $darkblue;
  color: white;
  font-size: 20px;
  border-radius: 10px;
  text-align: center;
  /*  display: flex;
  align-items: center;
  justify-content: space-between; */

  h1 {
    font-weight: 500;
    margin: auto;
  }
  p {
    font-size: 14px;
    margin: 0;
    color: $white;
  }
  .close {
    color: white;
    cursor: pointer;
    font-size: 32px;
    font-weight: 100;
    position: absolute;
    right: 20px;
    z-index: 99;
  }
}

.modalBody {
  padding: 40px 10px;
  position: relative;
  z-index: 2;
}

.modalFooter {
  padding: 20px 16px;
  background-color: $blue;
  color: white;
  position: relative;
  z-index: 2;
}

.open_modal {
  display: block;
}

.close_modal {
  display: none;
}
