@import "../../../styles/mixins.scss";
$--max-width: 1440px;

.launchpad {
  width: 100%;
  margin: 0 auto;
  .options_collection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 10px;
    //align-items: last baseline;
  }
}

.desc {
  position: relative;
  padding: 10px 0;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 32px;
    color: #fff;
  }

  h3 {
    font-weight: 600;
    font-size: 42px;
    line-height: 40px;
    color: #fff;
    padding-bottom: 20px;
  }

  .about {
    //font-weight: 600;
    font-size: 16px;
    line-height: 34px;
    color: #c0bdbd;
  }
}

.endDate {
  display: flex;
  justify-content: center;
  gap: 10px;
  text-align: center;
  width: 100%;
}

.hidden {
  display: none !important;
}

.policy {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
  a {
    width: 100%;
  }
  a .tos {
    background: #9469e0;
    width: 100%;
    height: 77px;
    border-radius: 12px;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #fff;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.back {
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 21px;
  color: #fff;
  display: flex;
  flex-direction: row;
  padding: 0px;

  padding: 0 15px;
  cursor: pointer;
}
.back_two {
  display: flex;
  height: 40px;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 24px;
  border: 1px solid #332f62;
  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    font-size: 14px;
    padding: 10px 14px;
  }
}

.typeSection {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  //margin: 50px;
  gap: 8px;

  .types {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: auto;
    padding: 5px;

    font-weight: 600 !important;

    color: #ffffffa6;
    background: $secondarybg;
    border-radius: 40px;

    .type {
      padding: 5px 20px;

      &:hover {
        cursor: pointer;
      }
    }

    .type_active {
      padding: 5px 20px;
      color: #fff;
      background: $active;
      border-radius: 32px;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

.launchViewHeader {
  display: flex;
  gap: 20px;
  max-width: $--max-width;
  padding-top: 20px !important;
  padding-bottom: 70px !important;
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  margin-top: 40px;
  position: relative;
  z-index: 0;

  @media (max-width: 1300px) {
    margin-top: 20px;

    .launchViewLeft {
      width: 50%;
    }

    .launchViewRight {
      width: 50%;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  @include mobile {
    margin-top: 20px;
    display: block;

    .launchViewLeft {
      width: 100%;
    }

    .launchViewRight {
      width: 100%;
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .titleBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: 16px;
    color: #8d8d8d;
    //padding: 0 15px;
    margin-bottom: 33px;

    @include mobile {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
    }

    .title {
      display: flex;
      align-items: center;

      img {
        @include mobile {
          display: none;
        }
      }

      h6 {
        font-family: "shapiro65_light_heavy_wide";
        font-size: 32px;
        font-style: normal;
        font-weight: 400;
        line-height: 49px;
        letter-spacing: 0em;
        text-align: left;
        color: white;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;

        @include mobile {
          font-size: 18px;
          padding: 0 0 !important;
        }
      }
    }

    .status {
      position: absolute;
      top: 15px;
      left: 0;
      right: 0;
      text-align: center;
      font-size: 14px;
      color: #fff;
      width: fit-content;
      margin: 0 auto;
      padding: 4px 8px;
      border: 1px solid transparent;
      border-radius: 10px;
      background: #fa7753;
      border: 0.5px solid #ffffff;
      border-radius: 4px;
      z-index: 9999;

      @include mobile {
        // margin-top: 100px !important;
      }
    }

    .activeTab {
      color: #fff;
      background: linear-gradient(#3168f7, #01cdfd) padding-box,
        linear-gradient(#3168f7, #01cdfd) border-box;
    }
  }
}

.launchViewLeft {
  width: 50%;

  @include mobile {
    display: block;
    width: 100%;
    margin-top: 0;
  }

  .block {
    display: flex;

    @include mobile {
      display: block;
    }

    p {
      margin-top: 36px;
      font-weight: 300;
      font-size: 18px;
      text-align: left;
      line-height: 30px;
      color: #e1e1e1;
      padding: 0;
    }

    @include mobile {
      width: 100%;
    }
  }

  h6 {
    padding: 0 15px;
    font-size: 21px;
    text-align: center;
    margin-bottom: 0;
    font-family: Lexend;
    font-weight: 700;
    position: initial;
  }

  p {
    padding: 0 15px;
    font-size: 14px;
    text-align: center;
    color: #8d8d8d;
  }

  .social {
    text-align: center;
    align-items: end;

    a {
      padding: 0 10px;
      position: relative;
      top: -12px;
    }
  }
}

.launchViewRight {
  width: 50%;
  margin-left: 33px;

  @include mobile {
    width: 100%;
    margin-left: 0;
    margin-top: -65px;
  }

  .project_social {
    //margin-top: 20px;
    color: white;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: baseline;
    justify-content: right;

    img {
      width: 40px;
    }

    .fav_btn {
      min-width: 0 !important;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: transparent !important;
        border-color: transparent !important;
      }
    }
  }

  .nft_view {
    @include mobile {
      width: 100%;
      border-radius: 10px;
    }

    .mint_normal {
      width: 100%;
      height: 77px;
      border-radius: 12px;
      background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%), #f6f5ff;

      span {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;

        color: #fff;
      }
    }

    .price_text {
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: 0.1em;
      text-transform: uppercase;

      color: rgba(246, 245, 255, 0.6);
    }

    .mint_live {
      width: 100%;
      background: $secondarybg;
      border-radius: 50px;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      padding: 26px;
      margin: 20px 0;
      border: solid 1px $border-color;

      .price_infos {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        @include tablet {
          flex-direction: column;
          gap: 10px;
        }

        .price_text {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: rgba(246, 245, 255, 0.6);
          width: 50%;
        }

        .price_value {
          background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
            #f6f5ff;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          font-weight: 400;
          font-size: 20px;
          line-height: 29px;

          //padding: 10px 0;
        }
      }

      .endDate {
        text-align: center;
        width: 100%;
      }

      .countdown {
        text-align: center;
        width: 100%;

        font-weight: 700;
        font-size: 24px;
        line-height: 29px;

        h6 {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;

          color: #f6f5ff;
        }

        div {
          margin-bottom: 0px !important;
        }
      }

      .convert {
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #fafafa;

        span {
          color: rgba(250, 250, 250, 0.7);
        }
      }

      .buy {
        //background: linear-gradient(96.68deg, #62D9EC 0%, #9469E0 105.7%), #F6F5FF;
        //width: 50%;
        background-color: $blue;
        border-radius: 50px;
        white-space: nowrap;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #fff;
        padding: 10px 20px;
        width: 100%;
        margin-top: 14px;

        @include mobile {
          width: 100%;
          margin-top: 20px;
        }

        &:disabled {
          opacity: 0.5;
        }
      }

      .quantityContainer {
        display: flex;
        border-radius: 50px;
        padding: 10px 10px;
        background: $border-color;
        margin-top: 10px;
        align-items: center;
        justify-content: space-around;
        flex-direction: row-reverse;
        line-height: 24px;
        width: 50%;
        @include tablet {
          flex-direction: row-reverse;
          gap: 10px;
          margin: 0 auto;
        }

        @include mobile {
          align-items: center;
          justify-content: space-around;
          width: 100%;
        }

        .quantity {
          background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
            #f6f5ff;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;

          font-weight: 400;
          font-size: 20px;
          line-height: 29px;
        }
      }
    }

    .mint_countdown {
      margin-top: 20px;
      span {
        font-weight: 700;
        font-size: 18px;
        line-height: 29px;
      }

      @include mobile {
        width: 100%;
        text-align: center;
        margin: auto;
        margin-top: 20px !important;
        padding: 20px 0;
        border-top: solid 1px $border-color;
        span {
          font-size: 22px;
        }
      }

      .soon {
        background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
          #f6f5ff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;

        font-weight: 700;
        font-size: 20px;
        line-height: 29px;
      }
    }

    .mint {
      width: 100%;
      height: 77px;
      background: #171936;
      border-radius: 12px;

      span {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;

        h6 {
          font-weight: 700;
          font-size: 24px;
          line-height: 29px;

          margin-top: 20px;

          color: #f6f5ff;
        }
      }

      .soon {
        background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
          #f6f5ff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;

        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
      }
    }

    .status_message {
      display: block;
      margin: 25px 0;
      text-align: center;
      font-size: 12px;
      line-height: 19px;
      color: #8d8d8d;
    }

    .ProgressBar {
      .container {
        background: #3b3a3a;
        border-radius: 10px;
      }
    }

    .header_timer {
      //margin-bottom: 40px;

      .time {
        margin: auto;
        background: #2a2938;
        border: 1px solid #262636;
        border-radius: 15px;
        padding: 20px;
        margin-top: 30px;
        height: 150px;
        width: 100%;

        h6 {
          font-family: "Lexend";
          font-style: normal;
          font-weight: 900;
          font-size: 34px;
          line-height: 80px;
          text-align: left;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #ffffff;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        p {
          font-family: "Lexend";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height, or 158% */

          text-align: left;
          letter-spacing: 0.13em;
          text-transform: uppercase;

          color: #8d8d8d;
        }
      }
    }

    .header {
      position: relative;

      h3 {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        color: #fff;
      }

      .about {
        font-weight: 600;
        font-size: 16px;
        line-height: 34px;
        color: #c0bdbd;

        padding: 10px 20px;
      }

      .benefits {
        display: block;
        font-weight: 400;
        font-size: 11px;
        line-height: 13px;
        letter-spacing: 0.1em;
        text-transform: uppercase;

        color: #fffafd;

        .benefit_details {
          margin: 20px 0;

          label p {
            display: flex;
            gap: 10px;
            font-size: 14px;
            line-height: 26px;
            color: #f6f5ff;
            padding: 7px 0;
            font-weight: 500 !important;

            .text-green {
              color: #32fe9a;
            }

            b {
              font-weight: 300 !important;
              color: #fff;

              span {
                color: #8d8d8d;
              }
            }
          }
        }
      }

      .artist {
        display: flex;
        gap: 10%;
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        border-left-width: 0;
        border-right-width: 0;
        border-image: linear-gradient(
            to right,
            transparent,
            $border-color,
            transparent
          )
          1 stretch;

        margin: 20px 0;
        padding: 24px 0;

        .divider {
          background: rgba(56, 54, 78, 0.6);
          height: auto;
          width: 2px;
          margin: 2px;
        }

        span {
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: #fffafd;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          letter-spacing: 0.02em;

          color: #8877ea;
        }

        .profile {
          display: flex;
          gap: 10px;
          align-items: flex-end;

          img {
            width: 40px;
            border-radius: 50px;
          }
        }
      }

      .price {
        display: grid;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 37px;
        letter-spacing: -0.02em;
        color: #ffffff;

        span {
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #8d8d8d;
        }
      }

      .status {
        font-size: 14px;
        color: #fff;
        background: #fd3eda;
        border: 1.5px solid #ffffff;
        border-radius: 4px;
        padding: 4px 16px;
        position: absolute;
        right: 0%;
        top: 5%;
      }

      .upocming {
        font-size: 14px;
        text-align: center;
        color: #fff;
        background: #fa7753;
        border: 1.5px solid #ffffff;
        border-radius: 4px;
        padding: 4px 16px;
        width: 120px;
        margin: auto;
        margin-bottom: 15px;
      }

      .time {
        margin: auto;
        background: #2a2938;
        border: 1px solid #262636;
        border-radius: 15px;
        padding: 20px;
        //margin-top: 30px;
        height: 150px;
        width: 100%;

        h6 {
          font-family: "Lexend";
          font-style: normal;
          font-weight: 900;
          font-size: 34px;
          line-height: 80px;
          text-align: center;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #ffffff;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        .time_detail {
          font-family: "Lexend";
          font-style: normal;
          font-weight: 400;
          font-size: 12px !important;
          line-height: 0;
          text-align: center;

          letter-spacing: 0.07em;
          text-transform: uppercase;

          color: #8d8d8d;

          span {
            opacity: 0.4;
            padding: 0 15px;
          }
        }

        p {
          font-family: "Lexend";
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          /* identical to box height, or 158% */

          text-align: center;
          letter-spacing: 0.13em;
          text-transform: uppercase;

          color: #8d8d8d;
        }

        @include mobile {
          h6 {
            font-size: 24px !important;

            span {
              opacity: 0.4;
              padding: 0 10px;
            }
          }

          .time_detail {
            font-size: 10px !important;

            span {
              opacity: 0.4;
              padding: 0 10px;
            }
          }
        }
      }
    }

    .details {
      margin: 20px 0;

      label p {
        display: flex;
        justify-content: space-between;
        font-size: 14px;
        color: #8d8d8d;
        padding: 7px 0;
        font-weight: 300 !important;

        .text-green {
          color: #32fe9a;
        }

        b {
          font-weight: 300 !important;
          color: #fff;

          span {
            color: #8d8d8d;
          }
        }
      }
    }

    .mint {
      margin-top: 40px;

      span {
        font-weight: 300px;
        color: #8d8d8d;
        font-size: 12px;
        line-height: 19px;

        b {
          color: #fff;
          margin: 0 5px;
        }
      }
    }

    .input {
      display: inline-flex;
      width: 100%;
      margin: 20px 0;

      input {
        width: 100%;
        border: solid 2px #2f2f43;
        padding: 15px;
        text-align: center;
        font-weight: 700;
        font-size: 21px;
        line-height: 26px;
      }

      button {
        width: 90px;
        color: #fff;
        transition: ease-in-out 0.2s;
        border: 1px solid #2f2f43 !important;
        background: var(--secondary-bg)
          radial-gradient(circle, transparent 1%, var(--secondary-bg) 1%)
          center/15000% !important;

        &:first-child {
          border-right: none !important;
          border-radius: 10px 0 0 10px;

          &::before {
            content: url("../../../assets/minus.svg");
            display: block;
            margin-top: 7px;
          }
        }

        &:last-child {
          border-left: none !important;
          border-radius: 0 10px 10px 0;

          &::before {
            content: url("../../../assets/add.svg");
            display: block;
            margin-top: 7px;
          }
        }

        &:last-child,
        &:first-child {
          transition: background 0.4s;

          &:hover {
            background: #01cffdb0
              radial-gradient(circle, transparent 1%, #01cffdb0 1%)
              center/15000% !important;
          }

          &:disabled {
            background: var(--disabled-bg)
              radial-gradient(circle, transparent 1%, var(--disabled-bg) 1%)
              center/15000% !important;

            &:active {
              background-color: #272738 !important;
              background-size: 100% !important;
              transition: background 0s;
            }
          }

          &:active {
            background-color: #01cdfd !important;
            background-size: 100% !important;
            transition: background 0s;
          }
        }
      }
    }

    .footer {
      padding: 0;
      margin: 0 !important;
      float: none;

      #mint {
        &:disabled {
          img {
            filter: brightness(6);
          }
        }
      }

      button {
        background: #01cdfd radial-gradient(circle, transparent 1%, #01cdfd 1%)
          center/15000%;
        border-radius: 10px;
        width: 100%;
        height: 52px;
        margin-top: 20px;
        font-size: 18px;
        color: #1b1b1b;
        font-weight: 500;
        transition: background 0.8s;

        &:hover {
          background: #01cffdca
            radial-gradient(circle, transparent 1%, #01cffdca 1%) center/15000%;
          border: solid 2px #01cdfd;
        }

        &:active {
          background-color: #13c3ea83;
          background-size: 100%;
          transition: background 0s;
        }

        &:disabled {
          background: var(--disabled-bg);
          border: solid 2px var(--disabled-bg);
          color: var(--disabled-color);
        }
      }
    }
  }
}

.benefits_type {
  max-width: $--max-width;
  margin: auto;
  margin-top: 100px;
  //padding: 230px 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
  display: block;
  gap: 100px;

  @include mobile {
    display: block;
    width: 100%;

    padding: 20px;
    margin: 100px auto;
  }

  .title {
    display: flex;
    margin-bottom: 30px;

    img {
      margin-right: 15px;
    }
  }

  h6 {
    font-weight: 600;
    font-size: 24px;
    line-height: 53px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    span {
      background: linear-gradient(
          123.75deg,
          #9b83f9 53.46%,
          #8164f5 66.94%,
          #27aef8 80.94%,
          #31a2fa 97.26%
        ),
        #ffffff;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  .info_header {
    margin-top: 20px;
    max-width: 500px;

    @include mobile {
      width: 100%;
      margin-bottom: 100px;
    }

    p {
      margin: 20px 0;
      font-style: normal;
      font-weight: 200;
      font-size: 18px;
      line-height: 28px;

      color: #c1c4d7;
    }

    .buy {
      width: 165px;
      height: 58px;
      color: #fff;
      font-weight: 700;
      font-size: 16px;
      line-height: 18px;
      background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%);
      border-radius: 12px;
    }
  }

  .benefit {
    display: flex;
    gap: 32px;
    align-items: center;

    padding: 30px 0;
    margin-bottom: 20px;

    //background: rgba(255, 255, 255, 0.06);
    //border: 1.10831px solid rgba(255, 255, 255, 0.04);
    //border-radius: 26.5995px;

    @include mobile {
      margin-top: 20px;
      display: block;
    }

    .video {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      overflow: hidden;
      margin: 50px 0;

      @include mobile {
        max-width: 100%;
      }
    }

    .img {
      width: 200px;
      height: 200px;
      border-radius: 10px;
      overflow: hidden;

      @include mobile {
        max-width: 100%;
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      @include mobile {
        width: 100%;
      }
    }

    .desc {
      max-width: 500px;

      @include mobile {
        width: 100%;
      }

      h6 {
        font-weight: 600;
        font-size: 24px;
        line-height: 32px;
        color: #fff;
      }

      p {
        font-weight: 200;
        font-size: 16px;
        line-height: 24px;
        color: #999cb6;
      }
    }

    b {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #f6f5ff;
    }

    span {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      @include mobile {
        font-size: 13px !important;
        font-weight: 300;
      }
    }
  }

  table {
    text-align: left;
    border-radius: 15px;
    background: #1e1e2a;

    .tableHeader {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: #919191;
      border-bottom: 1px solid #262636;
      background: #1e1e2a !important;

      .tooltip_nfts {
        position: relative;
      }

      .tooltip_nfts:before {
        background: #9c2a6f;
        content: "Minted/Total Supply";
        position: absolute;
        text-align: center;
        color: white;
        font-size: 12px;
        padding: 2px 10px;
        border-radius: 3px;
        left: -10px;
        bottom: -10px;
      }

      .tooltip_nfts:after {
        width: 12px;
        height: 12px;
        background: #9c2a6f;
        content: "";
        position: absolute;
        left: 50px;
        bottom: 9px;
        transform: rotate(45deg);
        border-radius: 3px;
      }

      .tooltip_nfts:before,
      .tooltip_nfts:after {
        display: none;
      }

      .tooltip_nfts:hover::before,
      .tooltip_nfts:hover::after {
        display: block;
      }
    }

    @include mobile {
      display: none;
    }
  }

  tr {
    &:nth-child(odd) {
      background-color: #22222d;
    }

    color: #fff;

    td {
      padding: 30px 40px;
      font-weight: 400;

      &:first-child {
        display: flex;
        padding: 40px 40px;

        img {
          margin-right: 10px;
        }
      }

      span {
        color: #919191;
      }
    }
  }

  .tableHeader__mobile {
    background: #1e1e2a !important;
    border: solid 1px #262636;
    border-radius: 10px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;

    p {
      display: flex;
      justify-content: space-between;
      padding: 5px;
      border-bottom: solid 1px #262636;
    }

    @include desktop {
      display: none;
    }
  }

  .tableHeader__mobile__divider {
    background: #262636;
    width: 100%;
    height: 1px;

    @include desktop {
      display: none;
    }
  }

  .buy {
    background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%), #f6f5ff;
    width: 50%;
    height: 77px;
    border-radius: 12px;

    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #fff;

    &:disabled {
      opacity: 0.5;
    }
  }
}

.projectinfo {
  margin-top: 120px;

  .desc {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include mobile {
      display: block;
    }
  }

  .split {
    display: flex;
    justify-content: space-between;

    @include mobile {
      display: block;
    }
  }

  .info_card {
    background: #1e1e2a;
    border-radius: 15px;
    padding: 32px;
    text-align: center;

    //width: 100%;
    p {
      display: grid;
      font-family: "Lexend";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      align-items: center;
      text-align: center;
      color: #919191;
    }

    span {
      font-family: "Lexend";
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 26px;
      align-items: center;
      text-align: center;
      color: #ffffff;
    }

    &__left {
      margin: 26px 0 0 0;
    }

    &__center {
      margin: 26px 0 0 26px;
    }

    &__right {
      margin: 26px 0 0 26px;
    }

    &__wallet {
      min-width: 600px;
    }

    @include mobile {
      &__left,
      &__center,
      &__right {
        margin: 26px 0 !important;
      }

      &__wallet {
        min-width: 100% !important;
      }
    }
  }
}

.modalContent {
  position: relative;
  background-color: $secondarybg;
  // border: 4px solid rgba(255, 255, 255, 0.05);
  margin: auto;
  text-align: center;
  padding: 0;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  -webkit-animation-name: animatetop;
  -webkit-animation-duration: 0.4s;
  animation-name: animatetop;
  animation-duration: 0.4s;
  transition: ease-in-out 2s;
  border-radius: 10px;
  border: 1px solid $border-color;
  z-index: 9999;

  backdrop-filter: blur(10px);

  .content {
    padding: 80px 0;

    .text {
      padding: 40px 0;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;

      text-align: center;
      letter-spacing: -0.03em;

      color: #ffffff;
    }

    p {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;

      text-align: center;

      color: #ffffff;
      opacity: 0.7;
    }
  }

  @include mobile {
    width: 80%;
  }

  .paymentModal {
    padding: 40px 25px;

    .modalHeader {
      margin: auto;
      text-align: center;

      img {
        width: 152px;
        border-radius: 12px;
        border: 1px solid #24243c;
      }

      h6 {
        font-weight: 600;
        font-size: 28px;
        line-height: 36px;
        letter-spacing: -0.03em;

        padding: 30px 0;

        text-align: center;
        color: #ffffff;
      }
    }

    .modalBody {
      .checkbox {
        width: 20px;
        position: relative;

        label {
          width: 20px;
          height: 20px;
          cursor: pointer;
          position: absolute;
          top: 0;
          left: 0;
          //background: #666674;
          //background: linear-gradient(top, #1D1B3A 0%, #1D1B3A 40%, #1D1B3A 100%);
          //border-radius: 10px;
          border: solid 2px #fff;

          &:after {
            content: "";
            width: 25px;
            height: 12px;
            position: absolute;
            top: -5px;
            left: 4px;
            border: 5px solid #fff;
            border-top: none;
            border-right: none;
            background: transparent;
            opacity: 0;
            transform: rotate(-45deg);
          }

          &:hover::after {
            opacity: 0.5;
          }
        }

        input[type="checkbox"] {
          visibility: hidden;

          &:checked + label:after {
            opacity: 1;
          }
        }
      }

      .items {
        padding: 30px 0;

        .checkbox_item {
          display: flex;
          gap: 10px;

          a {
            color: #fff;
            text-decoration: underline;
          }
        }

        .item {
          display: flex;
          justify-content: space-between;
          padding: 8px 0;

          font-weight: 400;
          font-size: 16px;
          line-height: 24px;

          .left {
            display: flex;
            gap: 10px;

            color: #fff;

            span {
              color: #fff;
              font-weight: 300;

              a {
                color: #9469e0;
              }
            }
          }

          .right {
            color: #fff;

            .mintInput {
              width: 100%;
              padding: 15px;
              margin: auto !important;
              text-align: center;
              font-weight: 700;
              font-size: 21px;
            }

            .input {
              display: flex;
              align-items: center;
              justify-content: center;

              width: 100px;

              background: $input-color;
              border-radius: 12px;
              border: 1px solid $border-color;

              padding: 8px 14px;

              input {
                width: 100%;
                margin: 0 16px;

                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
              }

              .minus,
              .plus {
                font-weight: 500;
                font-size: 18px;
                line-height: 24px;

                &:hover {
                  color: #887dea;
                }

                &:disabled {
                  color: #d1cdcd;
                  opacity: 0.3;
                }
              }
            }
          }
        }
      }

      .document {
        text-align: left;
        label {
          font-weight: 300;
          color: #8d8d8d;
        }
      }

      .input_lg {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        background: $input-color;
        border-radius: 12px;
        border: 1px solid $border-color;

        padding: 8px 14px;
        margin: 10px 0 !important;

        input {
          width: 100%;
          margin: 0 16px;

          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
        }
      }

      .divider {
        background: #2b2843;
        height: 1px;
        width: 100%;
        margin: 12px auto;
      }

      .buy {
        background: linear-gradient(96.68deg, #62d9ec 0%, #9469e0 105.7%),
          #f6f5ff;
        width: 100% !important;
        height: 55px;

        //padding: 30px 0;
        border-radius: 10px;
        border: none;

        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #fff;

        &:hover {
          border: none;
        }

        &:disabled {
          filter: grayscale(0.3) opacity(0.5);
        }
      }
    }
  }
}

.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
  text-align: center;
}

.loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}

.loader div:nth-child(1) {
  animation-delay: -0.45s;
}

.loader div:nth-child(2) {
  animation-delay: -0.3s;
}

.loader div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes loader {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.paymentProviders {
  //margin: 20px 0;
  margin-bottom: 20px;
  .title {
    text-align: left;
    padding: 15px 0 !important;
  }
  .providers {
    width: 100%;
    padding: 15px 0 !important;

    display: flex;
    gap: 10px;
    justify-content: center;

    @include mobile {
      display: block;
    }
    .provider {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;

      background: rgba(255, 255, 255, 0.05);
      border: $border;
      border-color: $border-color;
      border-radius: 12px;

      label {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: center;
        width: 100%;
        padding: 15px 0 !important;
        &:hover {
          cursor: pointer;
        }
      }

      input[type="radio"] {
        opacity: 0;
        position: absolute;
        width: 0;
        height: 0;
        margin: 0;
      }
      @include mobile {
        margin: 20px 0;
      }
    }
    .provider_active {
      background-color: rgba(105, 89, 167, 0.446) !important;
      border: 1px solid $primary !important;
    }
  }
}

.gallery {
  margin-top: 100px;
  padding: 0 20px;

  .info_header {
    padding-bottom: 20px;
    h6 {
      line-height: 33px;
    }
    span {
      font-size: 16px;
    }
  }
  .gallery_items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    @include mobile {
      display: block;
    }

    .item {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;

      .image {
        width: 100%;
        height: 100%;
        min-height: 350px;
        overflow: hidden;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

.fan_media {
  margin: 50px 0;
  padding: 0 20px;

  .folder_info {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 30px;

    img {
      height: 160px;
      width: 200px;
    }
    .folder_content {
      display: flex;
      flex-direction: row;
      font-size: 18px;
      //font-weight: bold;
      cursor: pointer;
    }
    @include mobile {
      img {
        width: 140px;
        height: auto;
      }
      flex-direction: column;
      align-items: center;
      gap: 40px;
    }
  }

  .file_content {
    margin-top: 20px;
    background: $border-color;
    padding: 20px;
    border-radius: 10px;

    h1 {
      display: flex;
      align-items: center;
      text-decoration: underline;

      &:hover {
        cursor: pointer;
        opacity: 0.5;
        transition: ease-in-out 0.5s;
      }
    }
    @include mobile {
      margin-top: 40px;
    }
  }

  .video_preview {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 500px;

    @include mobile {
      height: 300px;
    }

    .close_icon {
      cursor: pointer;
      align-self: flex-end;
      right: 10px;
      top: 10px;
    }

    h3 {
      margin-bottom: 1em;
      font-size: 1.2em;
      color: #333;
    }

    video {
      width: 100%;
      height: 100%;
      border: 1px solid $border-color;
      border-radius: 4px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }
  }

  .info_header {
    padding-bottom: 20px;
    //display: flex;
    //gap: 20px;
    //align-items: center;
    h6 {
      line-height: 33px;
    }
    span {
      font-size: 16px;
    }
  }
  .gallery_items {
    display: block;

    @include mobile {
      display: block;
    }

    .item {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;

      .image {
        width: 100%;
        height: 300px;
        overflow: hidden;
        border-radius: 10px;
        span img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }

  .album {
    margin-bottom: 50px;
  }

  .album_info {
    display: flex;
    align-items: center;
    gap: 20px;
    height: 160px;
    width: 160px;
    .image {
      //width: 100%;
      width: 120px;
      height: 120px;
      flex-shrink: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 20px;
        border: solid 2px $border-color;
      }
    }
    .album_track_genre {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      height: 100%;
      width: 100%;

      span {
        font-size: 18px;
        //font-weight: bold;
        white-space: nowrap;
      }
    }
  }
  .loop_player {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: 600px;
    object-fit: cover;
    border-radius: $border-radius;
    @include mobile {
      height: 300px;
    }
  }
  .album_tracks {
    width: 100%;
    transition: ease-in-out 400ms;
    animation: normal 10s linear infinite;
    margin-top: 20px;
    position: relative;

    @include mobile {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    @keyframes normal {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(100%);
      }
    }

    .album_track {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      //width: 60%;
      height: 100%;
      gap: 10px;
      //margin-bottom: 20px;
      border-radius: 10px;
      padding: 10px;
      &:hover {
        background: $border-color;
      }

      @include mobile {
        width: 100%;
        flex-direction: column !important;
        gap: 10px;
        &:hover {
          background: none;
        }
      }

      .track_id_info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        span {
          font-size: 18px;
          font-weight: bold;
        }
      }

      .mediaPlayer {
        width: 100%;
        background: $secondarybg;
        border-radius: 20px;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;

        padding: 26px 0;
        border: solid 1px $border-color;

        .playSong {
          display: flex;
          align-items: center;
          width: 100%;
          text-align: right;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: rgba(246, 245, 255, 0.6);
        }

        .playing {
          width: 100%;
          text-align: right;
          font-weight: 400;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.1em;
          text-transform: uppercase;

          color: rgba(246, 245, 255, 0.6);
        }

        .equalizer {
          height: 100%;
          width: 100%;
          place-content: center;
          padding: 10px 0 !important;
        }
      }
    }
    .file_folder {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      padding-left: 20px;
      padding-bottom: 10px;
    }
  }
}
