@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.creators_container {
  width: calc(25% - 10px) !important;
  min-width: 270px;
  max-width: 270px;
  height: 300px;
  margin: 10px 0;
  background-color: $secondarybg;
  border-radius: $border-radius;
  border: solid 1px $border-color;
  position: relative;
  overflow: hidden !important;
  display: flex;
  justify-content: center;

  @include mobile {
    width: 100% !important;
    min-width: 100%;
    height: auto;
    margin: auto;
  }

  // Add blur filter here

  span {
    width: 100% !important;
  }

  &:hover {
    background: transparent !important;
    box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0) !important;

    .club_details {
      background: #000000d1 !important;
      position: absolute;
      bottom: 0;
      left: 0;
      @include mobile {
        bottom: 0;
      }

      .details {
        opacity: 1;
        bottom: 0;
      }

      .content {
        opacity: 1 !important;
        display: block;
      }
    }
  }

  .loading {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgb(59, 59, 59);
  }

  .nft_image {
    //width: 60% !important;
    object-fit: cover !important;
    position: relative;
    box-shadow: 0px 0px 3px 1.5px #0d0d0d6e;
    border-radius: $border-radius;
    margin: 20px;
  }

  .nft_image_loading {
    height: 80% !important;
    width: auto !important;
    object-fit: cover !important;
    position: relative;
    left: 20%;
    top: 10%;
  }
  .drops_container {
    position: absolute;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 160%;
    width: 100%;
    height: 100%;
    filter: blur(12px);

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: ease-in-out 400ms;
    }
  }
  .club_details {
    width: 100%;
    position: absolute;
    bottom: -180px;
    left: 0;
    height: 180px !important;
    border-radius: 10px;
    overflow: hidden !important;
    transition: ease-in-out 400ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      .details {
        opacity: 1 !important;
      }
      .content {
        opacity: 0 !important;
      }
    }

    @include mobile {
      .details {
        opacity: 1 !important;
      }
      .content {
        opacity: 0 !important;
      }
    }
    .details {
      //opacity: 0;
      transition: ease-in-out 400ms;
      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;
        padding: 0 10px;
        margin-top: 10px;
      }
    }
    .content {
      padding: 10px;
      opacity: 0;
      font-weight: 400 !important;
      font-size: 13px !important;
      line-height: 18px !important;
      color: #999cb6 !important;
      transition: ease-in-out 400ms;
    }
  }
}
