@import "/src/styles/mixins";

.container {
  width: $feed-width;
  max-width: 100%;
  margin: 20px auto;
  position: relative;

  @include mobile {
    width: 90%;
    margin: auto;
  }

  .contentArea {
    padding: 20px;
    margin-bottom: 20px;

    border: $border;
    border-color: $border-color;
    border-radius: $border-radius;
    background: $mainbg;

    .radio {
      display: flex;
      align-items: center;
      gap: 10px;
      margin: 10px 0;

      .radioButton {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        position: relative;

        input {
          width: 20px;
          height: 20px;
        }

        input[type="radio"] {
          position: absolute;
          opacity: 0;

          + .radioLabel {
            font-weight: 600;

            &:before {
              content: "";
              background: #414040;
              border-radius: 100%;
              border: 2px solid transparent;
              display: inline-block;
              width: 1.4em;
              height: 1.4em;
              position: relative;
              top: 0;
              margin-right: 0.3em;
              vertical-align: top;
              cursor: pointer;
              text-align: center;
              transition: all 250ms ease;
            }
          }

          &:checked {
            + .radioLabel {
              &:before {
                background-color: $secondary;
                box-shadow: inset 0 0 0 3px #414040;
              }
            }
          }

          &:focus {
            + .radioLabel {
              &:before {
                outline: none;
                border-color: $secondary;
              }
            }
          }
        }
      }
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    .profile {
      display: flex;
      gap: 15px;

      img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
      }
    }
  }

  textarea {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: auto;
    min-height: 100px;
    padding: 10px;
    margin-top: 10px;
    border: $border;
    border: solid 2px;
    border-color: $border-color;
    border-radius: $border-radius;

    &:focus {
      border-color: $secondary !important;
      border: solid 2px;
    }
  }

  .imagePreview {
    width: 100%;
    margin: 10px 0;
    padding: 5px;

    border: $border;
    border-color: $border-color;
    border-radius: $border-radius;

    &:hover {
      background-color: $input-color;
      cursor: pointer;
    }
  }

  .audio_player {
    width: 100%;
    margin: 10px 0;
    padding: 1px;
    cursor: pointer;
    background: linear-gradient(
      138deg,
      $primary 4%,
      $primary 49%,
      $secondary 97%
    );
    border-radius: 50px;
  }

  audio::-webkit-media-controls-panel {
    background-color: #090909;
  }

  audio::-webkit-media-controls-mute-button {
    background-color: #6f7274;
    border-radius: 50%;
  }

  audio::-webkit-media-controls-play-button {
    background-color: #6f7274;
    border-radius: 50%;
  }

  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    color: #6f7274;
    text-shadow: none;
    /* Remove the default shadow */
  }

  audio::-webkit-media-controls-play-button:hover {
    background-color: rgba(100, 103, 104, 0.7);
  }

  audio::-webkit-media-controls-timeline {
    background-color: #6f7274;
    border-radius: 25px;
    margin-left: 10px;
    margin-right: 10px;
  }

  audio::-webkit-media-controls-volume-slider {
    background-color: #6f7274;
    border-radius: 25px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    padding: 10px 0 20px;

    img {
      width: 35px;
      height: 35px;

      padding: 5px;

      border: $border;
      border-color: $border-color;
      border-radius: $border-radius;

      &:hover {
        background-color: $input-color;
        cursor: pointer;
      }
    }

    .upload_music_icon {
      width: 35px;
      height: 35px;
      padding: 5px;
      border: $border;
      border-color: $border-color;
      border-radius: $border-radius;

      &:hover {
        background-color: $input-color;
        cursor: pointer;
      }
    }
  }

  .createButton {
    padding: 10px;
    background: linear-gradient(95.63deg, $primary 0%, $secondary 106.47%);
    border-radius: 50px;
    //border: $border;
    //border-color: $border-color;
    color: $textColor;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;

    &:hover {
      opacity: 0.7 !important;
      cursor: pointer;
    }

    &:disabled {
      background-color: $input-color;
      cursor: not-allowed;
    }
  }

  .createButtonSend {
    padding: 7px 14px;
    background-color: $secondary !important;
    border-radius: 50px;

    &:hover {
      background: $hoverColor !important;
      cursor: pointer;
    }

    &:disabled {
      color: inherit;
      background-color: $disabledColor !important;
      border-color: $input-color;
      cursor: not-allowed;
    }
  }

  .close {
    position: absolute;
    top: -12px;
    right: 15px;

    background-color: rgb(39 40 41);
    color: #ffffff80;
    border-radius: 50px;

    width: auto;
    height: auto;
    padding: 0px 10px;

    font-size: 14px;

    &:hover {
      background-color: rgb(48, 49, 50);
      cursor: pointer;
    }
  }
}

@media (max-width: 430px) {
  audio::-webkit-media-controls-current-time-display,
  audio::-webkit-media-controls-time-remaining-display {
    display: none;
  }
}
