@import "../../../styles/mixins";

.mediaPlayer_small {
  max-width: 240px;
  border-radius: 50px !important;

  .playSong {
    text-align: left !important;
  }

  .playing {
    text-align: left !important;
  }

  .equalizer {
    width: 90% !important;
    height: 50px !important;
  }
}

.mediaPlayer {
  width: 100%;
  border-radius: 12px;

  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 10px 26px;
  border: solid 1px $border-color;
  background: $secondarybg;

  .playSong {
    width: 100%;
    text-align: right;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;

    color: rgba(246, 245, 255, 0.6);
  }

  .playing {
    width: 100%;
    text-align: right;
    font-weight: 400;
    font-size: 11px;
    line-height: 13px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    z-index: 2;

    color: rgba(246, 245, 255, 0.6);
  }

  .equalizer {
    width: 75%;
    height: 80px;
    padding: 10px 0 !important;
    left: 0;

    @include mobile {
      padding: 25px 0 !important;
    }
  }
}