@import "../styles/mixins";

.sidebar {
  display: flex;
  //gap: 30px;
  //justify-content: space-between;
  width: 100%;
  height: 100%;

  .bodyContent {
    display: flex;
    //width: calc(66% - 300px);
    height: 100%;
    //margin: 0 auto;
    width: 100%;
    //padding: 0 40px;
    //margin-right: 0;
    //padding: 0 20px !important;
    margin-top: 0;
    padding-bottom: 50px !important;
    margin-bottom: 100px;
    //overflow: hidden scroll;
    //overflow: hidden;
    overflow: hidden;
    position: relative;

    @include tablet {
      width: calc(100% - 100px);
      padding: 0 20px;
    }

    @include mobile {
      width: 100%;
      height: calc(100% - 50px);
      padding: 0 !important;
      //margin-top: 80px;
      //padding-bottom: 50px;
      overflow: initial;
    }
  }

  .sidebarCollapse {
    //width: 100px !important;
    /* span {
      display: none;
    } */
    button {
      img {
        display: block !important;
      }
    }
  }

  .sidebarContent {
    text-align: left;
    min-width: 300px;
    height: 100vh;
    //background-color: $secondarybg;
    //border-right: solid 1px $border-color;
    z-index: 999;

    @include tablet {
      min-width: 100px;
      width: 100px;

      span {
        font-size: 12px;
        display: none;
      }
    }

    @include mobile {
      min-width: 0 !important;
      width: 0% !important;
      border-right: none !important;

      span {
        font-size: 12px;
        display: block;
      }
    }

    .walletSidebar {
      width: 100%;
      min-width: 100px;
      max-width: 100px;

      height: calc(100% - 80px);
      padding: 40px 20px;
      background-color: transparent;
      //border-left: $border;
      //border-color: $border-color;
      margin: auto;
      position: fixed;

      @include tablet {
        display: none;
      }
    }

    .navbar {
      width: 100%;
      min-width: 300px;
      max-width: 300px;

      height: calc(100% - 80px);
      padding: 40px 20px;
      background-color: transparent;
      border-right: $border;
      border-color: $border-color;
      margin: auto;
      position: fixed;

      @include tablet {
        width: 100%;
        min-width: 100px;
        max-width: 100px;
        height: 100%;
        span {
          display: none;
        }
      }

      @include mobile {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        height: $mobileMenuHight;
        padding: 0;
        z-index: 999;
        border-top: solid 1px #ffffff20;

        hr {
          display: none;
        }

        span {
          display: block;
        }

        .logo {
          display: none;
        }

        ul {
          margin-top: 0 !important;
          display: flex;
          justify-content: space-evenly;
        }

        li a {
          display: block !important;
          padding: 0 !important;
        }
      }
    }

    .navbar_hm {
      @include mobile {
        position: inherit !important;
        display: none !important;
      }
    }

    .logo_section {
      display: flex;
      align-items: center;

      gap: 10px;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;

      color: #ffffff;

      .logo {
        width: 30px;
      }
    }

    .active {
      padding: 14px 17px;
      background: #fff;
      border-radius: 50px;
      background: $primary;
      border: 1px solid $border-color;
      span {
        color: $text;
      }
      @include mobile {
        padding: 10px 15px;
        border-radius: 12px;
        background-color: $primary;
      }
    }

    /* ul {
      margin-top: 50px;
    } */

    li {
      padding: 14px 17px;
      margin: 10px 0;
      @include mobile {
        padding: 0 20px;
      }
    }

    li a {
      display: flex;
      align-items: center;

      gap: 20px;
      //padding: 25px 0;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;

      color: $text;
      font-weight: 600;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.668);
      z-index: -2;
      backdrop-filter: blur(10px);
    }

    .contentDrawer {
      position: absolute;
      top: 0;
      left: 0;
      padding: 40px;

      width: 100%;
      min-width: 400px;
      height: 100vh;
      background: $secondarybg;
      border-right: solid 1px $border-color;
      border-left: solid 1px $border-color;
      z-index: -1;
      overflow: hidden;
      transition: ease-in-out 0ms;

      @include mobile {
        width: 100%;
        top: 70px;
        left: 0 !important;
        bottom: 0 !important;
        transition: ease-in-out 0ms !important;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 20px;
      }

      h3 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        color: black;
      }

      .menuDrawer {
        display: flex;
        flex-wrap: wrap;
        text-align: center;

        li {
          width: 50%;
        }
      }
    }

    position: relative;

    @include mobile {
      position: inherit;
    }

    .footer {
      position: absolute;
      bottom: 20px;
      left: 0;
      right: 0;
      width: 100%;

      margin: auto;
      padding: 0 20px !important;

      @include mobile {
        display: none;
        visibility: hidden;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100% !important;

        img {
          display: none;

          @include tablet {
            display: block;
          }
        }
      }
    }
  }

  .desktopMenu {
    display: block;
    li a {
      display: flex;
      align-items: center;
      .icon {
        svg {
          font-size: 24px;
          color: $text;
        }
      }
    }

    @include mobile {
      display: none !important;

      a {
        text-align: center;
      }

      img {
        width: 30px;
      }
    }
  }

  .mobileMenu {
    background: $background;
    display: none;
    align-items: center;
    height: inherit;

    a {
      text-align: center;
    }
    .icon {
      svg {
        font-size: 22px;
        color: $text;
      }
    }
    img {
      width: 22px;
    }

    @include mobile {
      display: block;
    }
  }
}
