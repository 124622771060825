.starting {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  margin-top: 30px;
}

.middle_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #1c1c1c;
  width: 551px;
  border-radius: 20px;
  .go_to_my_profile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-bottom: 20px;
  }
}

.middle_img {
  background-image: url("https://loop-markets.directus.app/assets/239a1f0b-55df-4dfe-96ab-31eabdaf14f0");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 150px;
  h1 {
    font-size: 28px;
  }
  border-radius: 20px 20px 0px 0px;
}

.text {
  padding: 20px;
  margin-bottom: 40px;
  h1 {
    font-size: 30px;
    color: white;
  }
}

.get_started_button {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 28px;
  border: 1px solid #5500ab;
  color: white;
  font-size: 16px;
  font-weight: 600;
  &:hover {
    transition: 0.5s;
    background: #5500ab;
  }
}
@media (max-width: 580px) {
  .middle_content {
    width: 100% !important;
    border-radius: 0px !important;
  }
  .middle_img {
    border-radius: 0px !important;
  }
}
