@import "../../../styles/mixins";

.notification_content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  //margin: 20px 0;
  //padding-bottom: 50px;

  justify-content: space-between;
  text-align: center;


  .actions {
    display: flex;
    gap: 20px;
    justify-content: center;
    width: 100%;
    margin: 20px;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      margin: 0 auto !important;
      cursor: pointer;
    }

    @include mobile {
      flex-direction: column;
      gap: 10px;
    }
  }


  .image_content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
  }

  button {
    border-radius: 50px;
    width: 100% !important;
    color: #000;
  }

  .view-nft {
    background: #C83E93;
  }

  .socials {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    a {
      text-decoration: none;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff !important;
      margin: 0 10px;
      cursor: pointer;
    }
  }
}

.title {
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  margin-top: 40px;
  color: white;
}

.description {
  font-weight: 400;
  font-size: 17px;
  line-height: 25px;
  text-align: center;
  margin-bottom: 10px;
}

.transactionId {
  padding: 16px;
  background: #212326;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 50px;
  //width: 100%;
  width: 80%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  a {
    color: #01CDFD;
    font-weight: 500;
    margin-left: 5px;
  }
}


.lds_ring {
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
}
.lds_ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 100px;
  height: 100px;
  margin: 9px;
  border: 9px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #01CDFD transparent transparent transparent;
}

.lds_ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds_ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds_ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}