.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  gap: 20px;
}
.title {
  font-size: 24px;
  font-weight: bold;
  line-height: 28px;
  color: #fff;
  margin-bottom: 10px;
}
.follows_list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
