@import "../../../styles/mixins";

.wallet_login_wrapper {
  width: 100%;
  margin: auto;

  span {
    font-size: 12px;
    font-weight: 400;
    color: rgb(113, 118, 123);
  }

  .divider {
    margin: 10px 0 15px 0;
    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
  .list {
    width: 80%;
    margin: 20px auto;

    p {
      display: flex;
      text-align: left;
      padding: 4px 0;

      img {
        margin-right: 10px;
      }
    }
  }

  .signup {
    width: 280px;
    height: 57px;
    border-color: #fff !important;
    color: #fff !important;

    &:hover {
      border-color: transparent !important;
    }

    @include mobile {
      width: auto;
      min-width: 100px !important;
    }
  }

  .loginbtn {
    width: 100%;
    min-width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
