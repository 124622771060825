@import "../styles/mixins";

.header {
  position: fixed;
  top: 0;
  width: 100%;
  height: fit-content;
  flex-shrink: 0;
  border-bottom: 1px solid $border-color;
  background: $background;
  padding: 0 10px;
  z-index: 9999;

  @include mobile {
    //display: none;
    position: fixed;
    z-index: 9999;
    padding: 0px;
  }

  .items {
    display: flex;
    align-items: center;
    justify-content: space-between;

    //height: 100%;
    height: auto;
    padding: 15px 20px;

    .left {
      display: flex;
      align-items: center;

      .nav {
        display: flex;
        align-items: center;
        gap: 30px;
        margin-left: 240px;

        @include tablet {
          margin-left: 40px !important;
        }

        @include mobile {
          display: none;
        }

        a {
          font-weight: 700;
          font-size: 16px;
          line-height: 18px;
          color: #c5c6ca !important;
          text-decoration: none;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            bottom: -5px;
            left: 0;
            width: 0;
            height: 2px;
            background: #c5c6ca;
            transition: all 200ms ease-in-out;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }

        .artistSignup {
          color: #fff;
          background: $primary;
          border-color: $primary;
          border-radius: 50px;
          padding: 8px 14px;
          font-weight: bold;
          font-size: 16px;
          line-height: 18px;
          text-decoration: none;
          // mask text
          background: linear-gradient(
            120deg,
            $secondary,
            $primary,
            #c5c6ca,
            $primary,
            $secondary
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          // animatate gradient right to left
          background-size: 500% 500%;
          animation: gradient 5s ease infinite;
          @keyframes gradient {
            0% {
              background-position: 0% 50%;
            }
            50% {
              background-position: 100% 50%;
            }
            100% {
              background-position: 0% 50%;
            }
          }
        }
      }
      .logo_section {
        display: grid;
        text-align: center;
        width: auto;
        gap: 10px;
        font-weight: 500;
        font-size: 18px;
        line-height: 18px;
        color: #ffffff;

        .logo {
          width: 200px;
          @include mobile {
            width: 160px;
          }
          a {
            display: flex;
            gap: 15px;
            align-items: center;
            @include mobile {
              gap: 6px;
            }
          }
        }
      }
    }

    .right {
      display: flex;
      align-items: center;
      gap: 10px;

      .profile {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 50px;
        border: 2px solid $primary;
        position: relative;
        gap: 10px;
        cursor: pointer;
        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          &:hover {
            opacity: 0.7;
          }
        }
        p {
          color: #f6f5ff;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        .icon {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background: rgba(255, 255, 255, 0.05);
          display: flex;
          align-items: center;
          justify-content: center;

          .tooltip {
            position: absolute;
            bottom: -35px;
            background: rgba(255, 255, 255, 0.05);
            color: white;
            padding: 2px 5px;
            border-radius: 5px;
            z-index: 9;
            display: flex;
            align-items: center;
            span {
              font-weight: bold;
              font-size: 14px;
            }
          }

          .tooltip span {
            margin-left: 5px;
          }
        }
      }

      .button {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 150px;
        padding: 8px 14px;
        margin-right: 10px;

        font-weight: 700;

        color: $text;
        background: linear-gradient(120deg, $secondary, $primary);
        border-color: $primary;
        border-radius: 50px;

        @include mobile {
          min-width: 100px;
        }

        &:hover {
          color: #fff;
          background: $primary !important;
          border-color: $primary;
        }
      }

      .loginButton {
        width: auto;
        //min-width: 150px;
        padding: 8px 14px;
        margin: 0 10px;
        border-radius: 50px;
        color: $text;

        @include mobile {
          min-width: 70px;
          padding: 5px 5px;
          margin: 0 5px;
        }
      }

      .signupButton {
        width: 150px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        padding: 8px 10px;
        color: $text;
        font-weight: bold;

        background: $primary;
        border-color: $primary;
        border-radius: 50px;

        @include mobile {
          width: 66px;
          padding: 5px 5px;
        }

        &:hover {
          color: black;
          background: white;
          border-color: $primary;
        }
      }
      .darkmode {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        border: 1px solid $border-color;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        svg {
          font-size: 22px;
          &:hover {
            opacity: 0.5;
          }
        }
      }
    }
  }

  .dropdown {
    display: flex;
    position: relative;
    img {
      width: 20px !important;
      height: 20px !important;
      flex-shrink: 0;
    }
  }
  .dropdown_content {
    display: block;
    position: absolute;
    top: 50px;
    right: 0;

    width: 220px;
    min-width: 220px;
    background: rgb(41 42 47);
    padding: 20px 14px;
    border-radius: 10px;

    a {
      display: flex;
      align-items: center;
      gap: 5px;
      color: #f6f5ff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      padding: 8px 0;

      &:hover {
        color: $primary !important;
      }
    }
  }
}
