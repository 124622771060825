@import "../../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");
.smartvinil {
  width: 100%;
  height: 280px;
  border: 1px solid $border-color;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  @include tablet {
    height: 260px;
  }
  @include mobile {
    height: 220px;
  }
  @media (max-width: 544px) {
    justify-content: space-around;
  }
  @media (max-width: 414px) {
    flex-direction: column;
    height: fit-content;
  }

  &__vinil {
    width: 30%;
    height: 100%;
    border-right: 1px solid $border-color;
    border-radius: 10px;
    @media (max-width: 544px) {
      width: 40%;
      height: 90%;
    }
    @media (max-width: 425px) {
      width: 40%;
      height: 80%;
    }
    @media (max-width: 414px) {
      width: 50%;
      margin-top: 20px;
    }
    .image {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }
  }
  &__half {
    width: 9%;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include mobile {
      display: none;
    }
  }
  &__text {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 20px;
    @media (max-width: 414px) {
      padding: 0;
      padding-top: 10px;
      padding-bottom: 20px;
    }

    p {
      font-size: 18px;
      font-weight: bold;
      white-space: nowrap;
      font-family: "Squada One", sans-serif;
      font-weight: 400;
      font-style: normal;
      @media (max-width: 425px) {
        font-size: 16px;
      }
    }
    li {
      font-weight: bold;
      font-size: 18px;
      @media (max-width: 544px) {
        font-size: 14px;
      }
    }
    span {
      font-weight: 600;
    }
    @include mobile {
      width: 40%;
    }
  }
  &__artisImage {
    width: auto;
    height: 120%;

    .image {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      background: transparent;

      img {
        width: 100%;
        height: 100%;
        object-fit: stretch;
        border-radius: 10px;
        position: relative;
      }
    }
    @media (max-width: 544px) {
      display: none;
    }
  }
}
