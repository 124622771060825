@import '../../../styles/mixins';

.actions_container {
  background-color: #2c3236;
  height: auto;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2px 4px;
  position: relative;
}
.input_content {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  height: 90%;
  /* input {
    border-radius: 30px;
  } */
}
.btn {
  //background-color: $primary;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.3s ease;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn:hover {
  background-color: #2980b9;
}

/* Disable button when loading */
.btn[disabled] {
  opacity: 0.5;
  cursor: not-allowed;
}
