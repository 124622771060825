@import "../../../styles/mixins";

.visualizer {
  height: 20%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 3px;
  position: absolute;
  padding: 10px;
}

.track {
  width: 1.5%;
  height: 25%;
  background: linear-gradient(358deg, $blue, $primary);
  transition: 0.01s;
  border-radius: 10px;
}
.toggle_play {
  border: none;
  background-color: transparent;
  fill: blue;
  min-height: 25px;
  min-width: 25px;
  width: 2%;
  height: 2%;
  position: absolute;
  left: 110px;
}

#toggle-play:hover {
  cursor: pointer;
}
