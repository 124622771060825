@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");
.collection {
  padding: 20px;
  width: 100vw;
  //height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 40px;
  .back {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-weight: bold;
    width: fit-content;
    cursor: pointer;
    &:hover {
      opacity: 0.6;
    }
  }
  &__info {
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: row;
    @include mobile {
      flex-direction: column;
      align-items: center;
      gap: 20px;
      height: fit-content;
    }

    &_image {
      width: 50%;
      height: 90%;
      @include mobile {
        width: 70%;
      }
      .image {
        width: auto;
        height: 100%;
        overflow: hidden;
        border-radius: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
    &_text {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      @include mobile {
        width: 70%;
      }
      .status {
        background: $primary;
        width: fit-content;
        padding: 2px 14px;
        border-radius: 20px;
        font-size: 14px;
      }
      .collectionName {
        font-weight: bold;
        font-size: 18px;
      }
      .type {
        font-size: 14px;
        color: gray;
      }
      .message {
        font-size: 16px;
      }
      .line {
        border-bottom: 1px solid rgb(196, 195, 195);
      }
      button {
        cursor: pointer;
        background: $primary;
        font-weight: bold;
        border-radius: 20px;
        padding: 4px;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  &__benefits {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &_title {
      font-size: 24px;
      font-weight: bold;
      text-decoration: none; // Remove the default underline
      border-bottom: 3px solid $primary; // Use a border for the underline
      padding-bottom: 4px;
      width: fit-content;
      font-family: "Squada One", sans-serif;
      font-weight: 400;
      font-style: normal;
    }
    &_benefit {
      display: flex;
      flex-direction: column;
      gap: 10px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      span {
        font-size: 18px;
        font-weight: bold;
      }
      .imageInfo {
        display: flex;
        flex-direction: row;
        gap: 10px;
        p {
          &:nth-child(1) {
            font-size: 18px;
            font-weight: bold;
          }
          &:nth-child(2) {
            font-weight: 300;
          }
        }
        .image {
          width: 100px;
          height: 100px;
          overflow: hidden;
          border-radius: 10px;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
    &_benefitVideo {
      display: flex;
      flex-direction: column;
      gap: 20px;
      cursor: pointer;
      &:hover {
        opacity: 0.8;
      }
      span {
        font-size: 18px;
        font-weight: bold;
      }
      .image {
        width: 100%;
        height: auto;
        overflow: hidden;
        border-radius: 10px;
        background: $background;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}
