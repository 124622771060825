@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");
.disable-scroll {
  overflow: hidden !important;
}
.clubs {
  width: 100%;
  height: max-content;
  //max-height: 800px;
  //height: 970px;
  padding: 0 20px;
  margin-top: 40px;
  //margin-bottom: 200px;

  @include mobile {
    padding: 10px !important;
    margin-top: 30px;
  }

  @include tablet {
    padding: 20px;
  }
  .leaderboard_title_subtitle_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .leaderboard_title {
      display: flex;
      flex-direction: column;
      gap: 20px;
      h3 {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 600;
        font-size: 25px;
        line-height: 0;
        text-align: left;
        letter-spacing: -0.02em;
        color: #fff0fa;

        @include mobile {
          padding: 0;
          font-size: 25px;
          line-height: 24px;
        }
      }
      .subHeading {
        font-family: "Lexend";
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 26px;
        text-align: left;
        letter-spacing: -0.02em;
        color: #fff;
        opacity: 0.7;

        @include mobile {
          padding: 10px 0;
        }
      }
    }
    .leaderboard_input {
      background-color: #282828;
      height: 40px;
      border-radius: 5px;
      width: 250px;
      display: flex;
      align-items: center;
      margin-right: 33px;
      input {
        padding: 10px;
        width: 100%;
      }
    }
  }

  .club {
    display: flex;
    //justify-content: center;
    flex-wrap: wrap;
    text-align: left;
    gap: 20px;
  }

  &:hover {
    box-shadow: none !important;
    border: none !important;
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  .content {
    padding: 0 !important;
  }

  .item {
    flex-wrap: wrap;
    width: calc(33% - 20px) !important;
    margin: 10px 0;
    background-color: $secondarybg;
    border-radius: $border-radius;
    border: solid 1px $border-color;

    padding: 0 !important;

    position: relative;
    background: transparent;
    overflow: hidden !important;
    transform: translateY(0px) !important;

    span {
      width: 100% !important;
    }

    @include tablet {
      width: calc(50% - 20px) !important;
    }

    @include mobile {
      width: 100% !important;
    }

    img {
      height: 100% !important;
      width: 100% !important;
      margin: 0 !important;
      object-fit: cover !important;
      transition: ease-in-out 400ms;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(358deg, #000, transparent);
      transition: ease-in-out 400ms;
    }

    @include mobile {
      width: 100%;
    }

    .creator_vote {
      position: relative;
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      border-radius: 20px;
      padding: 0px 20px;

      cursor: pointer;
      background: #0b49d8;

      &:hover {
        background: #093aac;
      }

      span {
        color: white;
        font-size: 18px;
        font-weight: bold;
        /* background: linear-gradient(258deg, var(--purple), var(--blue));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
      }
    }

    .club_details {
      width: 100%;
      position: absolute;
      bottom: -100px;
      left: 0;

      height: 180px !important;

      border-radius: $border-radius $border-radius 0 0;
      overflow: hidden !important;
      transition: ease-in-out 400ms;

      @include tablet {
        bottom: -80px;
        .details {
          opacity: 1 !important;
        }
        .content {
          opacity: 0 !important;
        }
      }

      @include mobile {
        .details {
          opacity: 1 !important;
        }
        .content {
          opacity: 0 !important;
        }
      }

      .content {
        opacity: 0;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        color: #999cb6 !important;
        transition: ease-in-out 400ms;
      }

      .details {
        //opacity: 0;

        transition: ease-in-out 400ms;
      }

      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;

        padding: 0 10px;
        margin-top: 10px;

        b {
          font-size: 18px !important;
          color: #fff;
          font-weight: bold;
        }
      }
    }

    &:hover {
      background: transparent !important;
      box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0) !important;

      .club_details {
        background: #000000d1 !important;
        position: absolute;
        bottom: 0;
        left: 0;
        @include mobile {
          bottom: 0;
        }

        .details {
          opacity: 1;
          bottom: 0;
        }

        .content {
          opacity: 1 !important;
          display: block;
        }
      }
    }
  }
}
.tabs {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  width: 50%;
  z-index: 2;
  overflow: auto;
  border-bottom: solid 1px $border-color;
  @include mobile {
    width: 100%;
  }

  .tab {
    width: 50%;
    flex: none;
    text-align: center;
    padding: 17px;
    font-weight: 600;
    color: #c5c6ca;
    border-bottom: solid 1px transparent;
    //background: #111023ab;
    transition: ease-in-out 200ms;

    &:hover {
      //background: #21203e80;
      cursor: pointer;
    }
  }

  .active {
    border-bottom: solid 3px $secondary;
    color: $secondary;
  }
}
.categories_select {
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-top: 40px;
  margin-bottom: 30px;
  //margin-left: 70px;

  .select1 {
    display: flex;
    flex-direction: column;
    width: 300px;

    label {
      font-size: 14px;
    }
    select {
      font-size: 14px;
      background-color: $input-color;
      height: 40px;
      border-radius: 5px;
      width: 300px;
      padding: 8px;
    }
  }
  .select2 {
    display: flex;
    flex-direction: column;
    width: 300px;
    label {
      font-size: 14px;
    }
    select {
      font-size: 14px;
      background-color: $input-color;
      height: 40px;
      border-radius: 5px;
      width: 100%;
      padding: 8px;
    }
  }
}
@media (max-width: 600px) {
  .categories_select {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
.input_title_message {
  background: $input-color;
  width: 80%;
  height: 40px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  border: 1px solid $border-color;
}
input {
  padding: 20px;
  width: 100%;
  background: transparent;
  height: 100%;
  border-radius: 5px;
}

.text_area_modal {
  margin-top: 20px;
  width: 80%;
  height: auto;
  background: $input-color;
  padding: 20px;
  border-radius: 10px;
  text-align: justify;
  overflow-y: hidden;
  border: 1px solid $border-color;

  &:focus {
    border: 1.5px solid $border-color;
    box-shadow: 0px 0px 10px $border-color;
  }
}

.alert_message {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-size: 18px;
}
