.step_five_right_content {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  width: 235px;
  height: 310px;
  background: #5c5c5c;

  .step_five_preview {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;

    flex-direction: column;
    align-items: center;

    .step_five_loop_logo {
      width: 60px;
      height: 60px;

      img {
        width: 100%;
      }
    }
    .avatar_container {
      -webkit-border-radius: 50px; /* Add this line */
      border-radius: 50px; /* Add this line */
      .step_five_avatar {
        width: 90px;
        height: 90px;
        -webkit-border-radius: 50px; /* Add this line */
        border-radius: 50px; /* Add this line */
        background: #1a1a1a;
        img {
          height: 100%;
          width: 100%;
          -webkit-border-radius: 50px; /* Add this line */
          border-radius: 50px; /* Add this line */
        }
      }
    }

    .step_five_display_name {
      margin-top: 10px;
      margin-bottom: 20px;
      //background: rgb(0, 8, 17);
      padding: 2px 5px;
      border-radius: 10px;
      -webkit-border-radius: 10px;
      -moz-border-radius: 10px;
      -ms-border-radius: 10px;
      text-align: center;

      span {
        color: white;
        font-size: 14px;
        font-weight: bold;
        width: 130px;
        text-align: center;
      }
    }

    .preview_bottom {
      display: flex;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;

      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      margin-top: 30px;

      .preview_bottom_left {
        width: 70px;
        height: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .preview_bottom_rigth {
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 770px) {
  .step_five_right_content {
    width: 60%;
    height: 100% !important;
  }
}

.step_five_right_content_support {
  display: flex;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #5c5c5c;

  .step_five_preview_support {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
    width: 180px;
    .avatar_container_support {
      -webkit-border-radius: 50px; /* Add this line */
      border-radius: 50px; /* Add this line */
      .step_five_avatar_support {
        width: 40px;
        height: 40px;
        -webkit-border-radius: 50px; /* Add this line */
        border-radius: 50px; /* Add this line */
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
          -webkit-border-radius: 50px; /* Add this line */
          border-radius: 50px; /* Add this line */
        }
      }
    }

    .step_five_display_name_support {
      span {
        color: white;
        font-size: 14px;
        font-weight: bold;
        font-family: sans-serif;
        word-break: break-word;
        white-space: nowrap;
      }
    }
  }
}
