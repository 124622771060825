@import "../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.discover {
  width: 100%;
  height: max-content;
  //max-height: 800px;
  //height: 970px;
  padding: 0 20px;
  margin-top: 40px;
  //margin-bottom: 200px;

  @include mobile {
    padding: 10px !important;
    margin-top: 30px;
  }

  @include tablet {
    padding: 20px;
  }

  .container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .title {
      display: flex;
      gap: 20px;
      align-items: center;

      button {
        background: transparent !important;
        border: solid 2px $border-color !important;
        padding: 5px 25px !important;

        &:hover {
          background: $primary !important;
          color: white !important;
        }
      }
    }

    .artist_slider {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 30px 0;

      h1 {
        font-size: 24px;
        color: white;
      }

      .artist_box {
        display: flex;
        flex-direction: row;
        width: 100%;
        overflow: hidden;
        gap: 16px;
        flex-wrap: wrap;
        padding: 20px 10px;
      }
    }
    .music_awards {
      display: flex;
      flex-direction: column;
      width: 100%;

      height: 100%;
      gap: 10px;
      margin: 30px 0;

      h1 {
        font-size: 24px;
        color: white;
      }
      .leader_board_latest {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 10px;
        height: 100%;
        width: 100%;
        /* @include tablet {
          grid-template-columns: repeat(2, 1fr);
        }
        @include mobile {
          grid-template-columns: repeat(1, 1fr);
        } */
      }
    }
    .artist_collectibles {
      display: flex;
      flex-direction: column;
      width: 100%;

      height: 100%;
      gap: 10px;
      margin: 30px 0;

      h1 {
        font-size: 24px;
        color: white;
      }
      .leader_board_latest {
        display: flex;
        flex-direction: row;
        /* justify-content: center; */
        gap: 10px;
        flex-wrap: wrap;
      }
    }
    .popular_videos {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin: 30px 0;

      h1 {
        font-size: 24px;
        color: white;
      }
      .videos {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
        width: 100%;
        height: 100%;

        video {
          width: 32%;
        }

        @include mobile {
          flex-direction: column;
          video {
            width: 80%;
          }
        }
      }
    }
  }

  .collection {
    display: flex;
    //justify-content: center;
    flex-wrap: wrap;
    text-align: left;
    gap: 20px;

    @include mobile {
      margin-top: 50px;
    }
  }

  &:hover {
    box-shadow: none !important;
    border: none !important;
  }

  b {
    color: #59d6e6;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
  }

  h3 {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 0;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff0fa;

    @include mobile {
      padding: 0;
      font-size: 38px;
      line-height: 24px;
    }
  }

  .subHeading {
    padding: 20px;
    font-family: "Lexend";
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 26px;
    text-align: left;
    letter-spacing: -0.02em;
    color: #fff;
    opacity: 0.7;

    @include mobile {
      padding: 10px 0;
    }
  }

  .content {
    padding: 0 !important;
  }

  .item {
    flex-grow: 1;
    //flex-basis: 300px;
    width: 440px;
    max-width: calc(33% - 20px);
    margin: 10px 0;
    background-color: $secondarybg;
    border-radius: $border-radius;
    border: solid 1px $border-color;

    padding: 0 !important;

    position: relative;
    background: transparent;
    overflow: hidden !important;
    transform: translateY(0px) !important;
    transition: ease-in-out 200ms;

    &:hover {
      border: solid 1px $blue;
    }

    @include tablet {
      flex-basis: 100%;
      max-width: calc(50% - 20px);
    }

    @include mobile {
      flex-basis: 100%;
      max-width: calc(50% - 10px);
    }

    img {
      height: 100% !important;
      width: 100% !important;
      max-width: 100% !important;
      margin: 0 !important;
      object-fit: cover !important;
      transition: ease-in-out 400ms;
    }

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(358deg, #000, transparent);
      transition: ease-in-out 400ms;
    }

    .type {
      position: absolute;
      display: inline-flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      top: 20px;
      right: 10px;

      font-weight: 500;
      font-size: 18px;
      color: #fff;

      border-radius: 50px;
      padding: 0px 15px;
      //border: 1px solid #3B395A;
      //background: #151331;
      background: -webkit-linear-gradient(358deg, $primary, $blue);

      span {
        color: var(--blue);
        /* background: linear-gradient(258deg, var(--purple), var(--blue));
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent; */
      }
    }

    .type_upcoming {
      background: #03011ecc;
      color: #fff;
    }

    @include mobile {
      width: 100%;
    }

    .club_details {
      position: absolute;
      bottom: 0px;
      left: 0;
      border-radius: 24px !important;
      overflow: hidden !important;
      transition: ease-in-out 400ms;

      @include mobile {
        top: 65%;
        .details {
          opacity: 1 !important;
          font-size: 14px !important;
        }
        .content {
          opacity: 1 !important;
        }
      }

      .content {
        opacity: 0;
        font-weight: 400 !important;
        font-size: 13px !important;
        line-height: 18px !important;
        color: #999cb6 !important;
        transition: ease-in-out 400ms;
      }

      .details {
        //opacity: 0;
        padding: 20px;
        transition: ease-in-out 400ms;
      }

      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;

        padding: 0 10px;
        margin-top: 20px;

        b {
          font-size: 18px !important;
          color: #fff;
          font-weight: bold;
        }
      }
    }

    span {
      width: 100%;
      height: 100%;
    }
  }
}
.creators_container {
  background-color: $secondarybg;
  border-radius: $border-radius;
  border: solid 1px $border-color;
  position: relative;
  background: transparent;
  overflow: hidden !important;
  transform: translateY(0px) !important;
  display: flex;
  height: 250px;
  width: calc(25% - 15px);

  span {
    width: 100% !important;
  }

  @include tablet {
    width: calc(50% - 20px) !important;
  }

  @include mobile {
    width: 100% !important;
  }

  img {
    height: 100% !important;
    width: 100% !important;
    margin: 0 !important;
    object-fit: cover !important;
    transition: ease-in-out 400ms;
  }
  &:hover {
    background: transparent !important;
    box-shadow: 0 1px 20px 0 rgb(113, 25, 181), 0 0 5px 1px rgba(0, 0, 0, 0) !important;

    .club_details {
      background: #000000d1 !important;
      position: absolute;
      bottom: 0;
      left: 0;
      @include mobile {
        bottom: 0;
      }

      .details {
        opacity: 1;
        bottom: 0;
      }

      .content {
        opacity: 1 !important;
        display: block;
      }
    }
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(358deg, #000, transparent);
    transition: ease-in-out 400ms;
  }
  .equalizer {
    position: absolute;
    left: 70%;
    font-size: 0;
    text-align: center;
    padding: 14px;
  }
  .equalizer_bar {
    transition: transform 150ms linear;
    display: inline-block;
    background: rgba(151, 12, 172, 0.7);
    width: 5px;
    height: 40px;
    border-radius: 1px;
    margin: 0 1px;
    transform: scaleY(0.1);
    transform-origin: center center;
  }
  .vote_button {
    position: absolute;
    border-radius: 20px;
    padding: 0px 20px;
    background: #43474f;
    box-shadow: 0 1px 50px 0 rgb(0, 0, 0);
    margin: 10px;
    cursor: pointer;
    color: white;
    font-weight: 700;
    box-shadow: 0 1px 50px 0 rgb(113, 25, 181), 0 0 10px 1px rgba(0, 0, 0, 0);
    z-index: 2;
    &:hover {
      background: #373a41;
    }
  }
  .club_details {
    width: 100%;
    position: absolute;
    bottom: -100px;
    left: 0;
    height: 180px !important;
    border-radius: 10px;
    overflow: hidden !important;
    transition: ease-in-out 400ms;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include tablet {
      bottom: -80px;
      .details {
        opacity: 1 !important;
      }
      .content {
        opacity: 0 !important;
      }
    }

    @include mobile {
      .details {
        opacity: 1 !important;
      }
      .content {
        opacity: 0 !important;
      }
    }
    .details {
      //opacity: 0;
      transition: ease-in-out 400ms;
      p {
        color: #fff;
        font-size: 18px !important;
        font-weight: 700;
        text-align: left;
        padding: 0 10px;
        margin-top: 10px;
      }
    }
    .content {
      padding: 10px;
      margin-left: 10px;
      opacity: 0;
      font-weight: 400 !important;
      font-size: 13px !important;
      line-height: 18px !important;
      color: #999cb6 !important;
      transition: ease-in-out 400ms;
    }
    .club_details_bottom {
      position: relative;
      padding: 10px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-direction: row;

      .club_details_bottom_song {
        width: 60%;
        white-space: nowrap;
        overflow: hidden;

        span {
          font-size: 14px !important;
          color: white !important;
          transition: ease-in-out 400ms;
          display: inline-block;
          animation: marquee 10s linear infinite;
        }

        @keyframes marquee {
          0% {
            transform: translateX(90%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      }
      .club_details_bottom_icons {
        width: fit-content;
        border-radius: 50px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }
    }
  }
}
.song_container {
  cursor: pointer;
  width: calc(33% - 10px);
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px solid $border-color;
  padding: 10px;
  border-radius: 10px;
  &:hover {
    background: transparent !important;
    box-shadow: 0 1px 20px 0 rgb(113, 25, 181), 0 0 5px 1px rgba(0, 0, 0, 0) !important;
  }

  @include mobile {
    width: 100%;
  }

  .artist_picture {
    width: 40%;
    height: 100px;
    object-fit: cover;
    display: flex;
    align-items: end;

    span {
      font-size: 12px;
      color: white;
      font-weight: bold;
      padding: 5px;
    }
  }
  .song_info {
    width: 40%;
    display: flex;
    flex-direction: column;
    padding-left: 10px;
    > span:nth-child(1) {
      font-weight: bold;
      font-size: 18px;
      color: white;
    }
    .club_details_bottom {
      width: 100%;

      .club_details_bottom_song {
        overflow: hidden;
        width: 100%;

        span {
          font-size: 14px !important;
          color: white !important;
          transition: ease-in-out 400ms;
          display: inline-block;
          animation: marquee 20s linear infinite;
          white-space: nowrap; /* Prevent text from wrapping */
        }

        @keyframes marquee {
          0% {
            transform: translateX(90%);
          }
          100% {
            transform: translateX(-100%);
          }
        }
      }
    }
  }
  .play_icon {
    width: 20%;
    display: flex;
    align-items: end;
    justify-content: center;
  }
}

@media (max-width: 460px) {
  .song_container {
    width: 400px;
  }
}
@media (max-width: 416px) {
  .song_container {
    width: 360px;
  }
}
@media (max-width: 380px) {
  .song_container {
    width: 340px;
  }
}
@media (max-width: 360px) {
  .song_container {
    width: 320px;
  }
}
