@import "../../../../../styles/mixins";

.link_socials {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
  span {
    color: grey;
  }
  .link {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px 0;
    .error {
      color: red;
    }

    .link_firts_part {
      display: flex;
      gap: 20px;
    }

    .link_input {
      background: $input-color;
      width: 100%;
      height: 40px;
      border-radius: 5px;
      display: flex;
      align-items: center;
    }
    input {
      padding: 20px;
      width: 100%;
      background: $input-color;
      height: 100%;
      border-radius: 5px;
    }
    .link_icon {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: 1px solid #3c3c3c;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 28px;
        height: 28px;
        padding: 5px;
      }
    }
  }
}
