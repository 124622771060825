@import "../../../styles/mixins";
.blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(24, 24, 24, 0.768);
  z-index: 999999;
}
.postBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgb(7, 1, 15);
  background: linear-gradient(
    0deg,
    rgba(7, 1, 15, 1) 60%,
    rgba(77, 31, 121, 1) 96%
  );
  box-shadow: 0 12px 20px 0 var(--shadow-1), 0 2px 4px 0 var(--shadow-2),
    inset 0 0 0 1px var(--shadow-inset);

  width: 30%;
  border-radius: 10px;
  height: fit-content;
  display: flex;
  flex-direction: column;

  .shares_header {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 20px;
    font-weight: 600;
    padding: 14px 15px 17px 15px;
    .cancel_icon {
      cursor: pointer;
      &:hover {
        opacity: 0.5;
      }
    }

    @include mobile {
      font-size: 18px;
    }
  }
  .social_icons {
    margin: 20px;
    .icon {
      flex-shrink: 0;
      border: 1px solid #313131;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        opacity: 0.7;
      }
      img {
        padding: 5px;
        width: 30px;
        height: 30px;
      }
      @include mobile {
        width: 35px;
        height: 35px;
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    @include mobile {
      gap: 10px;
    }
  }
  .copy_link {
    padding: 20px;
    display: flex;
    flex-direction: column;
    .copy_input {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      gap: 10px;
      padding: 10px;

      border-radius: 5px;
      margin-top: 20px;
      width: 100%;
      border: 1px solid #313131;
      height: 40px;

      img {
        position: relative;
        width: 20px;
        height: 20px;
      }

      input {
        width: 76%;
        padding: 26px;
        position: absolute;
        @include mobile {
          width: 70%;
        }
      }

      .button_copy {
        position: relative;
        background: $active;
        color: var(--white);
        border: none;
        padding: 3px 8px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }

      .button_copied {
        position: relative;
        background: #037c45;
        color: var(--white);
        border: none;
        padding: 3px 8px;
        border-radius: 5px;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
}
@media (max-width: 980px) {
  .postBox {
    width: 40%;
  }
}
@media (max-width: 600px) {
  .postBox {
    width: 50%;
  }
}

@media (max-width: 450px) {
  .postBox {
    width: 95%;
  }
}
