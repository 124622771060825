.setp_five_all {
  width: 100vw;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.step_five_category {
  //border-top: 1px solid #1d1d1d;
  width: 100%;
  gap: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  padding: 10px;

  h1 {
    font-size: 24px;
    margin-top: 10px;
    color: white;
  }
  .categories_select {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 10px;
    margin-bottom: 10px;
    //margin-left: 70px;

    .select1 {
      display: flex;
      flex-direction: column;
      width: 300px;

      label {
        font-size: 14px;
      }
      select {
        background-color: #282828;
        height: 40px;
        border-radius: 10px;
        width: 280px;
        padding: 8px;
      }
    }
    .select2 {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
      }
      select {
        background-color: #282828;
        height: 40px;
        border-radius: 10px;
        width: 100%;
        padding: 8px;
      }
    }
  }
  .border {
    border: 1px solid #1d1d1d;
  }
  .admin_button {
    background: #034af0;
    color: white;
    font-weight: bold;
    padding: 7px 10px;
    border-radius: 5px;
    &:hover {
      opacity: 0.7;
    }
  }
}

.step_five {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  width: 100%;

  .step_five_left {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
    justify-content: center;
    width: 300px;
    border-right: 1px solid #1d1d1d;
    border-top: 1px solid #1d1d1d;
    border-radius: 10px;
    h1 {
      margin-top: 10px;
      font-size: 20px;
      color: white;
    }

    .step_five_left_content {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .step_five_left_content_title {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #1d1d1d;
        span {
          font-size: 14px;
        }
        .step_five_left_content_title_span {
          font-size: 16px;
          color: white;
        }
      }
      .step_five_image_sample {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .step_five_image_sample_square {
          width: 150px;
          height: 200px;
          background: #5c5c5c;
          border-radius: 10px;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            object-fit: cover;
          }
          &:hover {
            opacity: 0.7;
          }
        }
        .step_five_image_sample_three {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .other_images {
            width: 50px;
            height: 60px;
            background: #5c5c5c;
            border-radius: 10px;
            cursor: pointer;

            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
              border-radius: 10px;
            }
            &:hover {
              opacity: 0.7;
            }
          }
        }
      }

      .upload_option {
        display: flex;
        flex-direction: column;
        gap: 10px;
        span {
          font-size: 14px;
        }
        .image_icon {
          width: 150px;
          height: 60px;
          background-color: #252525;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          &:hover {
            opacity: 0.7;
          }
          span {
            font-size: 12px;
          }
        }
      }
    }
  }
  .step_five_right {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 400px;
    height: 500px;
    margin-top: 10px;
    background: #1a1a1a;
    border-radius: 10px;
    h1 {
      margin-top: 10px;
      font-size: 20px;
      color: white;
    }
    span {
      width: 70%;
      font-size: 14px;
      margin-bottom: 20px;
      color: #787878;
    }
  }
}
@media (max-width: 770px) {
  .categories_select {
    display: flex;
    align-items: center;
    gap: 20px !important;
    flex-direction: column !important;
    .select2 {
      display: flex;
      flex-direction: column;
      label {
        font-size: 14px;
        color: white;
      }
      select {
        background-color: #282828;
        height: 40px;
        border-radius: 10px;
        width: 280px !important;
        padding: 8px;
      }
    }
  }
  .step_five {
    display: flex;
    flex-direction: column !important;
    align-items: center;
    justify-content: center;
  }
  .step_five_left {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}

.step_five_right_content {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60%;
  height: 70%;
  background: #5c5c5c;

  .step_five_preview {
    display: flex;
    flex-direction: column;
    align-items: center;

    .step_five_loop_logo {
      margin-top: -20px;
      width: 80px;
      height: 80px;
      img {
        width: 100%;
      }
    }
    .avatar_container {
      -webkit-border-radius: 50px; /* Add this line */
      border-radius: 50px; /* Add this line */
      .step_five_avatar {
        width: 100px;
        height: 100px;
        -webkit-border-radius: 50px; /* Add this line */
        border-radius: 50px; /* Add this line */
        background: #1a1a1a;
        img {
          height: 100%;
          width: 100%;
          -webkit-border-radius: 50px; /* Add this line */
          border-radius: 50px; /* Add this line */
        }
      }
    }

    .step_five_display_name {
      margin-top: 10px;
      margin-bottom: 20px;
      background: rgb(0, 8, 17);
      padding: 2px 5px;
      border-radius: 10px;
      text-align: center;

      span {
        color: white;
        font-size: 16px;
        font-weight: bold;
        width: 130px;
        text-align: center;
      }
    }
    .preview_bottom {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 40px;
      margin-top: 30px;

      .preview_bottom_left {
        width: 80px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }
      .preview_bottom_rigth {
        width: 50px;
        height: 50px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
@media (max-width: 770px) {
  .step_five_right_content {
    width: 60%;
    height: 100% !important;
  }
}

.step_five_right_content_support {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background: #5c5c5c;

  span {
    font-size: 18px;
    font-family: "Bebas Neue", sans-serif;
    color: white;
  }
  .step_five_preview_support {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 180px;
    gap: 10px;
    width: 180px;
    .avatar_container_support {
      -webkit-border-radius: 50px; /* Add this line */
      border-radius: 50px; /* Add this line */
      .step_five_avatar_support {
        width: 40px;
        height: 40px;
        -webkit-border-radius: 50px; /* Add this line */
        border-radius: 50px; /* Add this line */
        flex-shrink: 0;

        img {
          height: 100%;
          width: 100%;
          -webkit-border-radius: 50px; /* Add this line */
          border-radius: 50px; /* Add this line */
        }
      }
    }

    .step_five_display_name_support {
      span {
        color: white;
        font-size: 14px;
        font-weight: bold;
        font-family: sans-serif;
        word-break: break-word;
      }
    }
  }
}
.upload_music_button {
  //margin-top: 20px !important;
  //margin-bottom: 40px !important;
  display: flex;
  flex-direction: column;
  //border-top: 1px solid #1d1d1d;
  gap: 10px;
  .image_icon {
    width: 100%;
    height: 60px;
    background-color: #252525;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
    span {
      font-size: 12px;
    }
  }

  .input_song_title {
    font-size: 14px;
    text-align: center;
  }
}
.leaderboard_input {
  background-color: #282828;
  height: 40px;
  border-radius: 5px;
  width: 250px;
  display: flex;
  align-items: center;
  margin-right: 33px;
  input {
    padding: 10px;
    width: 100%;
  }
}
