@import "../../../../styles/mixins";

.first_step {
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  .first_step_top {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    h1 {
      color: white;
    }
    span {
      font-size: 14px;
      color: white;
    }
    hr {
      padding: 0 !important;
      margin: 0 !important;
    }
  }
  .select_images_icon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    .profile_image_icon,
    .cover_image_icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        font-size: 10px;
        color: #fff;
        margin-top: 5px;
      }
      &:hover {
        cursor: pointer;
        opacity: 0.7;
      }
    }
    .profile_image_icon {
      min-width: 100px;
      //height: 60px;
      background-color: #25252562;
      border: solid 1px $border-color;
      border-radius: 5px;
      padding: 10px;
    }
    .cover_image_icon {
      min-width: 180px;
      //height: 60px;
      background-color: #25252562;
      border: solid 1px $border-color;
      border-radius: 5px;
      padding: 10px;
    }
  }
  .preview_container {
    width: 70%;
    background: #1d1d1d;
    height: 65vh;
    border-radius: 5px;
    border: 1px solid #2c2c2c;
    display: flex;
    flex-direction: column;
    align-content: center;
    gap: 20px;

    .preview_title {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      span {
        font-size: 14px;
      }
    }
    .preview {
      width: 50%;
      height: 70%;
      background-color: #181818;
      position: relative;
      margin: 0 auto;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
    }
    .preview_cover {
      width: 100%;
      height: 40%;
      background-color: #565656;
      position: relative;
      margin: 0 auto;
      border-radius: 5px 5px 0px 0px;
    }
    .preview_profile_img {
      position: relative;
      bottom: 12%;
      left: 10%;
      height: 110px;
      width: 110px;
      background-color: #363636;
      border-radius: 50%;
    }
    .preview_infos {
      position: relative;
      bottom: 20px;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      span {
        color: rgb(35, 82, 157);
      }
      p {
        font-size: 14px;
        color: #818181;
      }
    }
  }
}
@media (max-width: 660px) {
  .first_step_top {
    h1 {
      padding: 10px !important;
      text-align: center;
    }
  }
}
