@import "../../../../styles/mixins";

.input {
  width: 280px !important;
  height: 50px;
  background: $input-color;
  border-radius: 8px;
  border: 1px solid $border-color;
  padding: 20px;

  &:focus {
    border: 1px solid $border-color;
    box-shadow: 0px 0px 8px $border-color;
  }
}

.remaining {
  font-size: 14px;
  color: grey;
}
