@import "../../../../styles/mixins";
.blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(24, 24, 24, 0.468);
  z-index: 999999;
}
.postBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0c0d0f;
  box-shadow: 0 12px 20px 0 var(--shadow-1), 0 2px 4px 0 var(--shadow-2),
    inset 0 0 0 1px var(--shadow-inset);
  min-height: 90%;
  width: 500px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: fit-content;
  display: flex;
  flex-direction: column;
  @include mobile {
    width: 90%;
  }
}
.overflow_a {
  height: 60%;
  overflow-y: auto;
}
.box_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  padding: 14px 15px 17px 15px;
  border-bottom: 1px solid #2c2c2c;
  @include mobile {
    font-size: 18px;
  }
}
.close_icon {
  cursor: pointer;
  display: flex;
}
.close_icon:hover {
  opacity: 0.8;
}

.header_left {
  display: flex;
  align-items: center;
  gap: 20px;
  @include mobile {
    gap: 10px;
  }
}

.header_rigth {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_save_modal {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  cursor: pointer;

  border-radius: 50px;
  padding: 3px 15px;

  background: -webkit-linear-gradient(358deg, $primary, $blue);

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    max-width: $feed-width;
    font-size: 18px;
  }
}
.button_save_modal_loading {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  cursor: pointer;

  border-radius: 50px;
  padding: 10px 15px;

  background: -webkit-linear-gradient(358deg, $primary, $blue);

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    max-width: $feed-width;
    font-size: 18px;
  }
}
.button_save_modal:hover {
  opacity: 0.9;
}
.genres_container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px;

  .genres_title {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding-bottom: 10px;
    border-bottom: 1px solid #2c2c2c;

    .selectedGenre {
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: 500;
      color: #887dea;
      cursor: pointer;
      border-radius: 50px;
      padding: 3px 10px;
      border: 1px solid #332f62;
      //background: #151331;
      &:hover {
        background: #8168e1 !important;
        color: #fff !important;
      }
    }

    span {
      width: 100%;
      font-size: 18px;
      color: #666666;
      white-space: pre-wrap;
      @include mobile {
        font-size: 16px;
      }
    }
  }
  .all_genres {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: left;

    gap: 16px;
    .genre {
      font-weight: 500;
      color: #887dea;
      cursor: pointer;
      border-radius: 50px;
      padding: 3px 10px;
      border: 1px solid #332f62;
      //background: #151331;
      &:hover {
        background: #8168e1 !important;
        color: #fff !important;
      }

      span {
        color: var(--blue);
        font-size: 16px;
        /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
      }
    }
    .genre_grey {
      font-weight: 500;
      color: #3c3c3c;
      cursor: pointer;
      border-radius: 50px;
      padding: 3px 10px;
      border: 1px solid #3c3c3c;
      //background: #151331;

      span {
        font-size: 16px;
      }
    }
  }
}
