@import "../../../../styles/mixins";
.blur {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(24, 24, 24, 0.768);
  z-index: 99999999;
}
.postBox {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0c0d0f;
  box-shadow: 0 12px 20px 0 var(--shadow-1), 0 2px 4px 0 var(--shadow-2),
    inset 0 0 0 1px var(--shadow-inset);
  min-height: 228px;
  width: 500px;
  border-radius: 10px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 60%;
  display: flex;
  flex-direction: column;
  z-index: 999999;
  @include mobile {
    width: 90%;
  }
}

.overflow_a {
  height: 60%;
  overflow-y: auto;
}
.box_header {
  position: relative;
  display: flex;
  justify-content: space-between;
  font-size: 20px;
  font-weight: 600;
  padding: 14px 15px 17px 15px;
  border-bottom: 1px solid #2c2c2c;
  @include mobile {
    font-size: 18px;
  }
}
.close_icon {
  cursor: pointer;
  display: flex;
}
.close_icon:hover {
  opacity: 0.8;
}

.header_left {
  display: flex;
  align-items: center;
  gap: 20px;
  @include mobile {
    gap: 10px;
  }
}

.header_rigth {
  display: flex;
  align-items: center;
  justify-content: center;
}
.button_save_modal {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  cursor: pointer;

  border-radius: 50px;
  padding: 3px 15px;

  background: -webkit-linear-gradient(358deg, $primary, $blue);

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    max-width: $feed-width;
    font-size: 18px;
  }
}
.button_save_modal_loading {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
  cursor: pointer;

  border-radius: 50px;
  padding: 10px 15px;

  background: -webkit-linear-gradient(358deg, $primary, $blue);

  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
  @include mobile {
    max-width: $feed-width;
    font-size: 18px;
  }
}
.button_save_modal:hover {
  opacity: 0.9;
}
.box_profile {
  display: flex;
  flex-direction: column;
}
.edit_profile_cover {
  width: 100%;
  min-height: 155px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include mobile {
    max-width: 100%;
    min-height: 105px;
    border-radius: 0;
  }
  @media (max-width: 768px) {
    min-height: 155px;
  }
  @media (max-width: 500px) {
    min-height: 135px;
  }
  @media (max-width: 400px) {
    min-height: 115px;
  }
  @media (max-width: 350px) {
    min-height: 105px;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(30 31 36 / 82%);
    z-index: 1;

    backdrop-filter: blur(10px);
  }
}

.edit_profile_picture {
  bottom: 50px;
  width: 100px;
  height: 100px;
  background: $mainbg;
  border-radius: 100px;
  z-index: 2;
  position: relative;
  margin-left: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100px;
    padding: 5px;
    @include mobile {
      width: auto;
    }
  }
}
.edit_profile_input {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
}

.crooper {
  position: relative;
  display: flex;
  justify-content: center;
  height: 300px;
  width: 100%;
  background: rgb(18, 18, 18);
}
.crooper img {
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.reactEas.update_imgyCrop_CropAreaGrid {
  width: 300px !important;
  height: 300px !important;
  color: rgba(255, 255, 255, 0.5);
}
