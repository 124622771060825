.step_two {
  display: flex;
  flex-direction: column;
  align-items: center;
  //border-top: 1px solid #1d1d1d;
  width: 100vw;
  gap: 50px;
  position: relative;
  .step_two_top {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 26px;
    width: 100%;
    h1 {
      color: white;
    }
    span {
      font-size: 14px;
      color: white;
    }
    hr {
      padding: 0 !important;
      margin: 0 !important;
    }
    .step_two_input {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 10px;
      .step_two_input_input {
        display: flex;
        //flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        padding-bottom: 20px;
        .input_complete {
          display: flex;
          flex-direction: column;
          gap: 4px;
          position: relative;
        }
      }
    }
  }
}
