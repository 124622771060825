@import "../../../styles/mixins";

.quests {
  width: 100%;
  height: max-content;
  overflow: hidden;
  padding-top: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 80px;
  &__top {
    margin-right: 120px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    &_title {
      font-family: "Squada One", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
      svg {
        color: orangered;
      }
    }
    &_hot {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
    @include mobile {
      margin-right: 0 !important;
    }
  }
  &__bottom {
    margin-right: 120px;
    &_title {
      font-family: "Squada One", sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 22px;
    }
    &_more {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
    }
    @include mobile {
      margin-right: 0 !important;
    }
  }
}
.quest {
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  img {
    width: 140px;
    height: 140px;
    border-radius: 10px;
    @include mobile {
      width: 120px;
      height: 120px;
    }
  }
  p {
    width: 150px;
    font-weight: 500;
    @include mobile {
      width: 130px;
    }
  }
  span {
    font-size: 14px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: auto; // Push span to the end of the container
    svg {
      font-size: 18px;
      color: gold; // Change color to gold
      fill: gold; // Ensure fill is also set to gold
    }
  }
}
