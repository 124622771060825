.progress_bar_top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  progress {
    margin-top: 10px;
    border-radius: 7px;
    width: 50%;
    height: 8px;
  }
  progress::-webkit-progress-bar {
    background-color: rgb(43, 43, 42);
    border-radius: 7px;
  }
  progress::-webkit-progress-value {
    background-color: rgb(79, 239, 154);
    border-radius: 7px;
    box-shadow: 0px 0px 0px 0px rgba(79, 239, 154);
  }
  progress::-moz-progress-bar {
    /* style rules */
    box-shadow: 0px 0px 4px 2px rgba(79, 239, 154);
  }
}

.progress_button {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 24px;
  border: 2px solid #8168e1;
  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }

  span {
    color: white;
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent; */
  }
}
.progress_button_disabled {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #777777;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px 24px;
  border: 2px solid #9a9a9a;
  //background: #151331;

  span {
    color: white;
  }
}
.progress_button_loading {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  font-weight: 500;
  color: #887dea;
  cursor: pointer;
  border-radius: 50px;
  padding: 10px 24px;
  border: 2px solid #8168e1;
  //background: #151331;
  &:hover {
    background: #8168e1 !important;
    color: #fff !important;
  }
  span {
    color: var(--blue);
    /* background: linear-gradient(258deg, var(--purple), var(--blue));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent; */
  }
}
@media (max-width: 770px) {
  progress {
    display: none !important;
  }
}
