@import "../../../../styles/mixins.scss";
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@500;600;700&display=swap");

.wrapper {
  .creators_container {
    background: transparent;
    border-radius: 10px;
    position: relative;
    background: transparent;
    overflow: hidden !important;
    transform: translateY(0px) !important;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 260px;
    height: fit-content;
    //height: 320px !important;
    margin: 10px;
    color: black;

    @media (max-width: 570px) {
      width: 300px !important;
      max-width: fit-content;
    }
    @media (max-width: 430px) {
      width: 100% !important;
    }

    span {
      width: 100% !important;
    }

    img {
      height: 100% !important;
      width: 100% !important;
      margin: 0 !important;
      object-fit: cover !important;
      transition: ease-in-out 400ms;
      border-radius: 10px;
      @media (max-width: 570px) {
        width: 300px !important;
      }
      @media (max-width: 430px) {
        width: 100% !important;
      }
    }

    .trackinfo {
      text-align: left;
      margin-top: 14px;
      color: $text;
      p:nth-child(1) {
        font-weight: bold;
      }
      p:nth-child(2) {
        color: gray;
      }
    }
  }
}
