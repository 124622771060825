@import "../../../styles/mixins.scss";

.conversation_container {
  width: 100%;
  &:hover {
    background-color: #292828;
  }
  .conversation_infos {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-top: 1px solid $border-color;
    cursor: pointer;
  }
  .sender_image {
    display: flex;
    gap: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    margin: 0 10px;
    img {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      margin: 0 -10px; 
    }
  }
  .sender_info {
    display: flex;
    flex-direction: column;
    :nth-child(1) {
      font-weight: bold;
    }
  }
}
.conversation_container_selected {
  width: 100%;
  background-color: $border-color;
  .conversation_infos {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    padding: 10px;
    border-top: 1px solid #2f2e2e;
    cursor: pointer;
  }
  .sender_image {
    display: flex;
    gap: -10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    flex-shrink: 0;
    margin: 0 10px;
    img {
      flex-shrink: 0;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      margin: 0 -10px; 
    }
  }
  .sender_info {
    display: flex;
    flex-direction: column;
    :nth-child(1) {
      font-weight: bold;
    }
  }
}
